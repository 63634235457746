import { Helmet } from 'react-helmet-async';
import { Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Our Frames
import BasicFrame from 'components/Frame/Basic';

// Our Pages
import AddClientDetails from 'components/Forms/AddClientDetails';
import Error404Page from 'pages/Error404Page';
import IntroPage from 'pages/IntroPage';
import LiabilitiesPage from 'components/Liabilities/LiabilitiesPage';
import LoadingLiabilitiesPage from 'pages/LoadingLiabilitiesPage';
import ProcessingMethodfiLiabilitiesPage from 'pages/ProcessingMethodfiLiabilitiesPage';
import VerifyAdvisor from 'pages/VerifyAdvisor';
import ProspectiveClientFinalPage from 'pages/ProspectiveClientFinalPage';
import DebtToleranceAssessment from 'pages/DebtToleranceAssessment';

// Liability Forms
import AutoLiability from 'components/Forms/Liabilities/AutoLiability';
import HomeLiability from 'components/Forms/Liabilities/HomeLiability';
import StudentLiability from 'components/Forms/Liabilities/StudentLiability';
import PersonalLiability from 'components/Forms/Liabilities/PersonalLiability';

// Constants
const DEFAULT_CACHE_TIME = 1000 * 60 * 10; // 1000 ms * 60s * 10 => 10 minutes

const isRunningLocally = process.env.NODE_ENV === 'development';

const QUERY_CLIENT = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 0,
			cacheTime: DEFAULT_CACHE_TIME
		},
		mutations: {
			retry: 0,
			cacheTime: DEFAULT_CACHE_TIME
		}
	}
});

function App() {
	return (
		<div className="App">
			<Helmet>
				<title>Sora Finance</title>
			</Helmet>

			<QueryClientProvider client={QUERY_CLIENT}>
				<Routes>
					<Route path="*" element={<Error404Page />} />
					<Route
						element={<VerifyAdvisor />}
						path="verify/advisor/:token"
					/>
					<Route
						element={<IntroPage />}
						path="prospective/client/intro"
					/>
					<Route element={<BasicFrame />}>
						<Route path="prospective">
							<Route path="client">
								{/* <Route path="intro" element={<IntroPage />} /> */}

								<Route
									path="create"
									element={<AddClientDetails />}
								/>
								<Route
									path="liabilities/identified"
									element={<LiabilitiesPage />}
								/>
								<Route
									path="dta"
									element={<DebtToleranceAssessment />}
								/>
								<Route
									path="final"
									element={<ProspectiveClientFinalPage />}
								/>
								<Route path="update">
									<Route
										path="auto/:tradeLineId"
										element={<AutoLiability />}
									/>
									<Route
										path="mortgage/:tradeLineId"
										element={<HomeLiability />}
									/>
									<Route
										path="student/:tradeLineId"
										element={<StudentLiability />}
									/>
									<Route
										path="personal/:tradeLineId"
										element={<PersonalLiability />}
									/>
								</Route>
							</Route>
						</Route>
					</Route>
					<Route
						path="prospective/client/liabilities/loading"
						element={<LoadingLiabilitiesPage />}
					/>
					<Route
						path="prospective/client/liabilities/loading/:accountHolderId"
						element={<ProcessingMethodfiLiabilitiesPage />}
					/>
				</Routes>
				{isRunningLocally && (
					<ReactQueryDevtools initialIsOpen={false} />
				)}
			</QueryClientProvider>
		</div>
	);
}

export default App;
