import { useMutation } from 'react-query';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { getUserLiabilitiesUrl as saveLiabilityEndpoint } from 'shared/api-urls';

function useMutateUpdateLiability() {
	return useMutation(async (liabilityPayload) => {
		const { tradelineId } = liabilityPayload;

		const urlForRequest = `${saveLiabilityEndpoint}${tradelineId}`;

		// dynamic entries will be patch calls
		const response = await axiosInstance.patch(
			urlForRequest,
			liabilityPayload
		);

		return response.data;
	});
}

export default useMutateUpdateLiability;
