import PropTypes from 'prop-types';
import { Paper, Grid, Typography } from '@mui/material';

function CardItem({ cardHeading, children, lender, LoanIcon, sx }) {
	return (
		<Paper
			elevation={3}
			square={false}
			sx={{
				marginLeft: 2,
				borderRadius: '20px',
				padding: 4,
				boxSizing: 'border-box',
				transition: 'all .2s ease-in-out',
				'&:hover': {
					transform: 'scale(1.03)'
				},
				...sx
			}}
		>
			<Grid container item>
				<Grid item xs={8}>
					<Typography variant="h5" sx={{ marginBottom: 1 }}>
						{cardHeading}
					</Typography>

					<Typography variant="body1">Lender: {lender}</Typography>
				</Grid>

				<Grid
					container
					item
					xs={4}
					sx={{
						justifyContent: 'flex-end',
						alignItems: 'flex-start',
						marginBottom: 2,
						transition: 'all .5s ease-in-out'
					}}
				>
					{LoanIcon}
				</Grid>
			</Grid>
			<Grid
				container
				item
				spacing={2}
				sx={{ justifyContent: 'space-between' }}
			>
				{children}
			</Grid>
		</Paper>
	);
}

CardItem.propTypes = {
	cardHeading: PropTypes.string.isRequired,
	lender: PropTypes.string.isRequired
};

export default CardItem;
