import { useMutation, useQueryClient } from 'react-query';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoint
import { updateDtsStatusUrl } from 'shared/api-urls';

// our query keys
import { DTS_STATUS, ERROR_MESSAGE_DATA } from 'shared/query-keys';

function useMutateSaveDta() {
	const queryClient = useQueryClient();
	return useMutation(
		async (dtsData) => {
			const response = await axiosInstance.post(
				updateDtsStatusUrl,
				dtsData
			);
			return response.data;
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(DTS_STATUS);
			},

			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error);
			}
		}
	);
}

export default useMutateSaveDta;
