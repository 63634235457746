import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Our Components
import SimpleGradientLoading from 'components/Loader/SimpleGradientLoading';

// Our Hooks
import useGetProfile from 'hooks/client/useGetProfile';
import useGetAllUserLiabilities from 'hooks/client/useGetAllUserLiabilities';

// Our Routes
import { IDENTIFIED_LIABILITIES_ROUTE } from 'routes';

function LoadingLiabilitiesPage() {
	const navigate = useNavigate();

	const { isSuccess } = useGetAllUserLiabilities(true);

	const { isSuccess: isProfileSuccess } = useGetProfile(
		(userProfile) => userProfile,
		true
	);

	useEffect(() => {
		if (isSuccess && isProfileSuccess) {
			navigate(IDENTIFIED_LIABILITIES_ROUTE);
		}
	}, [isSuccess, isProfileSuccess, navigate]);

	return <SimpleGradientLoading />;
}

export default LoadingLiabilitiesPage;
