import { Grid } from '@mui/material/index';

// Our Components
import Loader from 'components/Loader/index';
import { PrimaryButton } from 'components/Button/Button';

function FormButton({ isMutationLoading, isFormReady, submitLiabilityData }) {
	return (
		<Grid
			item
			xs={12}
			sm={10}
			lg={8}
			sx={{
				paddingTop: 2,
				paddingBottom: 2,
				marginLeft: 2,
				marginRight: 2
			}}
		>
			{isMutationLoading && <Loader />}
			{!isMutationLoading && (
				<PrimaryButton
					sx={{
						width: {
							xs: '95%',
							sm: '100%',
							md: 'fit-content'
						},
						marginTop: 1
					}}
					isDisabled={!isFormReady}
					onClick={submitLiabilityData}
				>
					Save
				</PrimaryButton>
			)}
		</Grid>
	);
}

export default FormButton;
