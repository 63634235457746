import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import LydiaReg from './assets/fonts/Lydia/LydiaSansRegular.woff2';
import LydiaSemiBold from './assets/fonts/Lydia/LydiaSansSemiBold.woff2';
import LydiaBold from './assets/fonts/Lydia/LydiaSansBold.woff2';

import GascogneTSLight from './assets/fonts/Gascogne/GascogneTSLight.woff';
import GascogneTSLight2 from './assets/fonts/Gascogne/GascogneTSLight2.woff2';

// Create the Sora theme
// eslint-disable-next-line import/no-mutable-exports
let soraTheme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#2685C1',
			soraBlue: '#2685C1',
			soraMariner: '#246cb3',
			lightBlue: '#97D8E8',
			lightShade: '#ECECEC',
			lightGrey: '#dbdbdb',
			grey: '#989898',
			darkGrey: '#454746',
			indigo: '#295983',
			iceBlue: '#C8E9F1DE',
			highEmphasis: 'rgba(0, 0, 0, 0.87)',
			mediumEmphasis: 'rgba(0, 0, 0, 0.6)',
			disabled: 'rgba(0, 0, 0, 0.38)',
			contrastText: '#ffffff',
			white: '#ffffff',
			black: '#000000'
		},
		secondary: {
			main: '#97D8E8',
			contrastText: '#000000'
		},
		warning: {
			main: 'rgb(255 188 0 / 32%)', // Subject to change
			mainHover: 'rgb(255 188 0 / 16%)' // Subject to change
		},
		error: {
			main: '#B00020'
		},
		success: {
			main: '#00a152'
		},
		gradient: {
			soraBlueToWhiteLinearGradient:
				'linear-gradient(180deg, #206EA7 0%, rgba(38, 133, 193, 0.21) 100%);',
			onboardingNavLinearGradient:
				'linear-gradient(180deg, rgba(182, 219, 238, 0.2) 0%, rgba(182, 219, 238, 0) 0.01%, #B6DBEE 111.23%);',
			soraSimpleGradient: 'linear-gradient(180deg, #B0CDE4 0%, #FFF 100%)'
		}
	},
	components: {
		MuiCssBaseline: {
			//  Below is css syntax
			styleOverrides: `
				@font-face {
					font-family: 'Lydia';
					font-style: normal;
					font-display: swap;
					font-weight: 400;
					src: local('Lydia'),
					local('Lydia-Regular'),
					url(${LydiaReg}) format('woff2');
					unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
					U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
				};
				@font-face {
					font-family: 'Lydia-Semi-Bold';
					font-style: normal;
					font-display: swap;
					font-weight: 400;
					src: local('Lydia-Semi-Bold'), local('Lydia-Semi-Bold'), url(${LydiaSemiBold}) format('woff2');
					unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
					U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
				};
				@font-face {
					font-family: 'Lydia-Bold';
					font-style: normal;
					font-display: swap;
					font-weight: 400;
					src: local('Lydia-Bold'), local('Lydia-Bold'), url(${LydiaBold}) format('woff2');
					unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
					U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
				};
				@font-face {
					font-family: 'Gascogne-light';
					font-size: 32px;
					font-style: normal;
					font-weight: 100;
					line-height: 40px;
					src: local('GascogneTSLight'), local('GascogneTSLight'), url(${GascogneTSLight}) format('woff');
					unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
					U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
				};
			`
		}
	},
	typography: {
		fontFamily:
			"'Lydia', 'Gascogne-light', 'Helvetica', 'Arial', sans-serif",
		fontWeightMedium: 600,

		h1: {
			fontWeight: 700,
			fontSize: '6rem',
			lineHeight: 1,
			letterSpacing: '-0.094rem'
		},
		h2: {
			fontWeight: 700,
			fontSize: '3.75rem',
			lineHeight: 1
			// no definition for letterSpacing: ''
		},
		h3: {
			fontWeight: 700,
			fontSize: '3rem',
			lineHeight: 1
			// no definition for letterSpacing: ''
		},
		h4: {
			fontWeight: 700,
			fontSize: '2.125rem',
			lineHeight: 1
			// no definition for letterSpacing: ''
		},
		h5: {
			fontWeight: 700,
			fontSize: '1.5rem',
			lineHeight: 1,
			letterSpacing: '0.0112rem'
		},
		h6: {
			fontWeight: 700,
			fontSize: '1.25rem',
			lineHeight: 1,
			letterSpacing: '0.0094rem'
		},
		subtitle1: {
			fontWeight: 700,
			fontSize: '1rem',
			lineHeight: 1.5,
			letterSpacing: '0.009rem'
		},
		subtitle1Link: {
			color: '#000',
			fontFeatureSettings: "'clig' off, 'liga' off",
			fontFamily: 'Lydia Sans',
			fontSize: '1rem',
			fontStyle: 'normal',
			fontWeight: 500,
			lineHeight: '16px',
			textDecorationLine: 'underline',
			'&:visited': {
				color: '#000',
				fontFeatureSettings: "'clig' off, 'liga' off",
				fontFamily: 'Lydia Sans',
				fontSize: '10px',
				fontStyle: 'normal',
				fontWeight: 500,
				lineHeight: '16px',
				textDecorationLine: 'underline'
			}
		},
		subtitle2: {
			fontWeight: 700,
			fontSize: '0.875rem',
			lineHeight: 1,
			letterSpacing: '0.006rem'
		},
		body1: {
			fontWeight: 400,
			fontSize: '1rem',
			lineHeight: 1.35,
			letterSpacing: '0.0313rem'
		},
		body2: {
			fontWeight: 400,
			fontSize: '0.875rem',
			lineHeight: 1,
			letterSpacing: '0.0156rem'
		},
		button: {
			fontWeight: 700,
			fontSize: '0.875rem',
			lineHeight: 1,
			letterSpacing: '0.0781rem'
		},
		caption: {
			fontWeight: 400,
			fontSize: '0.75rem',
			lineHeight: 1,
			letterSpacing: '0.025rem'
		},
		overline: {
			fontWeight: 600,
			fontSize: '0.625rem',
			lineHeight: 1,
			letterSpacing: '0.0938rem'
		},
		// Different font.
		h1Gl: {
			fontFamily: 'Gascogne-light',
			fontFeatureSettings: '"clig" off, "liga" off',
			fontSize: 48,
			fontStyle: 'normal',
			fontWeight: 100,
			lineHeight: '50px'
		},
		h2Gl: {
			fontFamily: 'Gascogne-light',
			fontWeight: 100,
			fontSize: 32,
			lineHeight: '1.25em'
		}
	}
});

soraTheme = responsiveFontSizes(soraTheme);

export default soraTheme;
