import PropTypes from 'prop-types';

const sx = PropTypes.oneOfType([
	PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
	),
	PropTypes.func,
	PropTypes.object
]);

const typography = PropTypes.oneOf([
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
	'subtitle1',
	'subtitle2',
	'body1',
	'body2',
	'button',
	'caption',
	'overline'
]);

export default { sx, typography };
