import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

// Axios Instance
import axiosInstance from 'services/API/API';

// our Endpoints
import { getMethodfiToken } from 'shared/api-urls';

function useMutateGetMethodfiToken() {
	const navigate = useNavigate();

	return useMutation(
		async (userDetailsSentToMFI) => {
			const response = await axiosInstance.post(getMethodfiToken, {
				type: 'individual',
				individual: userDetailsSentToMFI
			});

			const userData = response.data.data;

			return userData;
		},
		{
			onError: () => {
				navigate('/client/onboarding/unable-to-verify');
			}
		}
	);
}

export default useMutateGetMethodfiToken;
