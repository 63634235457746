import PropTypes from 'prop-types';
import numbro from 'numbro';
import { useCallback } from 'react';

// Our Components
import TextInput from 'components/Input/TextInput';

// Our Input types
import { TEXT } from 'components/Input/Types';

const getFormattedValue = (incomingValue) => {
	const isIncomingValueEmpty = incomingValue === '';
	if (isIncomingValueEmpty) return incomingValue;

	// if we reached this point the incomingValue is a string.
	// We turn it into a 'Number' type via unary cast operator
	const formattedValue = numbro(+incomingValue).format({
		thousandSeparated: true,
		mantissa: 0
	});

	// the formattedValue will always be a string.
	// i.e 0 => "0"
	// 5000 => "5,000"
	// 100000 => "100,000"

	// we return the formattedValue
	return formattedValue;
};

function MileageTextInput({ vehicleMileage, setVehicleMileage }) {
	const handleMileageCheck = useCallback((incomingValue) => {
		const isIncomingValueEmpty = incomingValue === '';
		if (isIncomingValueEmpty) {
			// if user is clearing the input just clear it
			setVehicleMileage('');
			return;
		}

		// if we reached this point we need to unformat the value that is incoming in so that we can properly evaluate it
		// in javascript 5,000 is not considered a number.
		// so we unformat it so it becomes "5000"
		const unformattedValue = numbro.unformat(incomingValue);

		// numbro.unformat will return undefined when the input is funky i.e. 5,000a where a represents any character
		// because of the unary operator this becomes NaN and isNum will yield false which prevents it from entering state
		const isNum = !Number.isNaN(+unformattedValue);

		if (isNum) {
			// if we reached this point numbro has removed the commas for us and given us number.
			// If you look at prop types below vehicleMileage is a STRING and our endpoint expects a STRING
			// hence we cast it back to a string here before officially putting it in state
			const backToStringValue = `${unformattedValue}`;
			setVehicleMileage(backToStringValue);
		}
	}, []);

	const formattedMileage = getFormattedValue(vehicleMileage);

	return (
		<TextInput
			type={TEXT}
			label="What is the mileage on your vehicle?"
			subLabel="Estimate is fine."
			value={formattedMileage}
			onChange={handleMileageCheck}
			inputProps={{
				'data-test': 'mileage'
			}}
		/>
	);
}

MileageTextInput.propTypes = {
	vehicleMileage: PropTypes.string.isRequired,
	setVehicleMileage: PropTypes.func.isRequired
};

export default MileageTextInput;
