export const ALL = 'ALL';
export const AUTO = 'AUTO';
export const MORTGAGE = 'MORTGAGE';
export const STUDENT = 'STUDENT';
export const PERSONAL = 'PERSONAL';
export const CREDITCARD = 'CREDITCARD';
export const HELOC = 'HELOC';
export const LOAN = 'LOAN';

// Valid Onboard Update States
export const ASSETS_UPDATED = 'ASSETS_UPDATED';
export const INCOME_ACCOUNT_CONNECTED = 'INCOME_ACCOUNT_CONNECTED';
export const INCOME_UPDATED = 'INCOME_UPDATED';
export const LIABILITIES_UPDATED = 'LIABILITIES_UPDATED';
export const LIABILITY_ACCOUNT_CONNECTED = 'LIABILITY_ACCOUNT_CONNECTED';
export const REVIEW_COMPLETED = 'REVIEW_COMPLETED';
export const SIGNUP_COMPLETED = 'SIGNUP_COMPLETED';

// Methodfi Status Constants
export const METHODFI_OPEN = 'METHODFI_OPEN';
export const METHODFI_SUCCESS = 'METHODFI_SUCCESS';
export const METHODFI_ERROR = 'METHODFI_ERROR';
export const METHODFI_USER_EXITED = 'METHODFI_USER_EXITED';

// Dts related
export const DTS_COMPLETED = 'DTS_COMPLETED';
export const DTS_NOT_STARTED = 'DTS_NOT_STARTED';
export const DTS_IN_PROGRESS = 'DTS_IN_PROGRESS';