import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';
import {
	Box,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText
} from '@mui/material';

// Our Assets
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

function NavOptions({ isMenuOpen }) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const items = useMemo(() => {
		if (isMenuOpen) {
			const navOptions = [
				{
					title: 'Documents',
					Icon: FileCopyOutlinedIcon,
					disable: true
				},
				{
					title: 'Loan Pre-Approval',
					Icon: CheckCircleOutlineIcon,
					disable: true
				}
			];
			const renderNavItems = () =>
				navOptions.map(({ disable, title, Icon }) => {
					const uniqueKey = uuidv4();
					return (
						<ListItem key={uniqueKey} disabled={disable}>
							<ListItemButton disabled={disable}>
								<ListItemIcon>
									<Icon
										sx={{
											color: {
												xs: primary.indigo,
												md: 'white'
											}
										}}
									/>
								</ListItemIcon>
								<ListItemText
									variant="subtitle1"
									sx={{
										color: {
											xs: primary.indigo,
											md: 'white'
										}
									}}
								>
									{title}
								</ListItemText>
							</ListItemButton>
						</ListItem>
					);
				});
			return renderNavItems();
		}

		return [];
	}, [isMenuOpen]);

	return (
		<Box>
			<List>{items}</List>
		</Box>
	);
}

export default NavOptions;
