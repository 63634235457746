import { Box, Divider, Typography } from '@mui/material';

// Our Components
import NavOptions from 'components/NavMenu/NavOptions';
import SoraSkeleton from 'components/Loader/Skeleton';

// Our Assets
import SoraLogoWhite from 'assets/icons/SoraLogoWhite.svg';

// Hooks
import useGetAdvisorProfile from 'hooks/advisor/useGetAdvisorProfile';

function StaticGradientNav() {
	const { isLoading, isSuccess, data } = useGetAdvisorProfile(true);

	if (isLoading) {
		<Box
			sx={{
				background:
					'linear-gradient(180deg, #206EA7 0%, rgba(38, 133, 193, 0.00) 100%);',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				alignItems: 'center'
			}}
		>
			<Box>
				<SoraSkeleton />

				<Divider />
				<NavOptions isMenuOpen />
			</Box>

			<Box
				component="img"
				src={SoraLogoWhite}
				sx={{ width: '100%', height: 50, marginBottom: 8 }}
			/>
		</Box>;
	}

	if (isSuccess) {
		const {
			profilePicUrl: advisorProfilePic,
			companyName,
			firstName,
			lastName
		} = data;

		return (
			<Box
				sx={{
					background:
						'linear-gradient(180deg, #206EA7 0%, rgba(38, 133, 193, 0.00) 100%);',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							textAlign: 'center',
							marginTop: 4,
							marginBottom: 4
						}}
					>
						<Box
							component="img"
							src={advisorProfilePic}
							sx={{
								maxHeight: 250,
								maxWidth: '50%',
								borderRadius: 3.14 * Math.pow(2, 4),
								marginBottom: 4
							}}
						/>

						<Typography
							variant="body1"
							sx={{
								color: 'white',
								wordWrap: 'break-word'
							}}
						>
							{firstName} {lastName}
						</Typography>

						<Typography
							variant="body2"
							color="white"
							sx={{ marginTop: 2 }}
						>
							{companyName}
						</Typography>
					</Box>

					<Divider sx={{ width: '70%', bgcolor: 'white' }} />
					<NavOptions isMenuOpen />
				</Box>

				<Box
					component="img"
					src={SoraLogoWhite}
					sx={{ width: '100%', height: 50, marginBottom: 8 }}
				/>
			</Box>
		);
	}

	return <></>;
}

export default StaticGradientNav;
