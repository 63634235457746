import { Grid } from '@mui/material/index';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import SoraSkeleton from 'components/Loader/Skeleton';

const TEMP_LIABILITIES_LIST = new Array(4).fill(0);

function LiabilitiesLoading() {
	return TEMP_LIABILITIES_LIST.map(() => (
		<Grid
			container
			item
			xs={12}
			md={6}
			lg={4}
			sx={{ justifyContent: 'center' }}
			key={uuidv4()}
		>
			<SoraSkeleton
				sx={{
					marginTop: 2,
					marginBottom: 2,
					alignSelf: 'center',
					borderRadius: Math.PI * 2
				}}
			/>
		</Grid>
	));
}

export default LiabilitiesLoading;
