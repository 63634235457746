/**
 * Takes a string and returns the string formatted as a phone number
 * @param {string} inputString
 * @returns {string}
 *
 * @example
 *
 * formatPhoneNumber(5555555555)
 * returns the following 555-555-5555
 */

function formatPhoneNumber(inputString) {
	if (inputString === '') return '';
	const cleanString = inputString.replaceAll('-', '');

	if (cleanString.length > 6) {
		const firstThreeDigits = cleanString.slice(0, 3);
		const fourthAndFifthDigits = cleanString.slice(3, 6);
		const restOfDigits = cleanString.slice(6);

		return `${firstThreeDigits}-${fourthAndFifthDigits}-${restOfDigits}`;
	}

	if (cleanString.length > 3) {
		// get the first 3 digits
		const firstThreeDigits = cleanString.slice(0, 3);
		const restOfDigits = cleanString.slice(3);
		return `${firstThreeDigits}-${restOfDigits}`;
	}

	return inputString;
}

export default formatPhoneNumber;
