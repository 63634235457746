import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

// Our Components
import SimpleGradientLoading from 'components/Loader/SimpleGradientLoading';

// Our Hooks
import useGetAdvisorProfile from 'hooks/advisor/useGetAdvisorProfile';
import useMutateAddUserMask from 'hooks/admin/useMutateAsAnotherUser';

// Our Routes
import { PROSPECTIVE_CLIENT_INTRO_ROUTE } from 'routes';

function VerifyAdvisor() {
	const navigate = useNavigate();

	// Here pull stuff off the url once available.
	const maskAsUser = useMutateAddUserMask();

	useEffect(() => {
		maskAsUser.mutate({
			masqSecret: process.env.REACT_APP_ADMIN_SECRET,
			unmasq: false
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { isSuccess } = useGetAdvisorProfile(maskAsUser.isSuccess);

	useEffect(() => {
		if (isSuccess) {
			navigate(PROSPECTIVE_CLIENT_INTRO_ROUTE);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess]);

	return (
		<Grid container>
			<SimpleGradientLoading
				loadingMessage="Loading..."
				loadingSubMessage=""
			/>
		</Grid>
	);
}

export default VerifyAdvisor;
