import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Api URLS
import { getUserLiabilitiesUrl } from 'shared/api-urls';

// QUery keys
import { USER_MORTGAGES } from 'shared/query-keys';

// our routes
import { IDENTIFIED_LIABILITIES_ROUTE } from 'routes';

// Our utils
import { getNonNullValues } from 'shared/utils';

function useGetUserMortgageLiability() {
	const { tradeLineId } = useParams();

	// if this is a isManualRoute do not do the data binding

	const urlWithTradeLineId = `${getUserLiabilitiesUrl}${tradeLineId}`;

	return useQuery(
		[USER_MORTGAGES, tradeLineId],
		async () => {
			const response = await axiosInstance.get(urlWithTradeLineId);

			// This is the data from Sora Api which includes meta
			const Liability = response.data;

			// This is the actual mortgage Data
			const mortgageData = Liability.data;

			return mortgageData;
		},
		{
			refetchOnWindowFocus: false,
			select: ({
				expectedPayOffDate,
				escrow,
				financialGoal,
				interestRate,
				lender,
				loanType,
				monthlyPay,
				outstandingBalance,
				propertyOwnershipTenure,
				tenureMonth,
				clientHome
			}) => {
				// get rid of garbage data before giving it to HomeLiability Component
				const mortgageDataWithoutNullValues = getNonNullValues({
					expectedPayOffDate,
					escrow,
					financialGoal,
					interestRate,
					lender,
					loanType,
					monthlyPay,
					outstandingBalance,
					propertyOwnershipTenure,
					tenureMonth,
					clientHome
				});

				return mortgageDataWithoutNullValues;
			}
		}
	);
}

export default useGetUserMortgageLiability;
