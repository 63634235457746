import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

// Our Common Props
import CommonPropTypes from 'shared/prop-types';

// Our Components
import Footer from 'components/Footer/Footer';
import MenuAppBar from 'components/MenuAppBar';
import StaticGradientNav from 'components/NavMenu/StaticGradientNav';

function BasicFrame({ sx }) {
	// A container for a basic 12 column grid
	return (
		<Grid container sx={{ height: '100vh', width: '100%', ...sx }}>
			<Grid
				item
				sx={{
					display: { xs: 'block', md: 'none' },
					maxHeight: 60,
					marginBottom: 2
				}}
			>
				<MenuAppBar />
			</Grid>

			<Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }}>
				<StaticGradientNav />
			</Grid>

			<Grid container item md={10} sx={{ alignItems: 'flex-end' }}>
				<Outlet />
				<Grid
					container
					item
					xs={12}
					sx={{
						borderTop: '0.25px solid #000',
						justifyContent: 'center',
						alignItems: 'center',
						gap: 5,
						paddingTop: 2,
						paddingBottom: 2
					}}
				>
					<Footer />
				</Grid>
			</Grid>
		</Grid>
	);
}

BasicFrame.propTypes = {
	sx: CommonPropTypes.sx
};

BasicFrame.defaultProps = {
	sx: {
		width: '100%'
	}
};

export default BasicFrame;
