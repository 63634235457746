import { useState } from 'react';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

// Our Components
import Nav from 'components/NavMenu/Nav';

// Our Assets
import SoraLogoBlack from 'assets/icons/SoraLogoBlack.svg';

export default function MenuAppBar() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenuOpen = () => setIsMenuOpen(!isMenuOpen);

	return (
		<>
			<AppBar position="static" sx={{ width: '100vw', bgcolor: 'white' }}>
				<Toolbar
					disableGutters
					sx={{
						justifyContent: 'space-between',
						alignItems: 'center',
						bgcolor: 'white',
						paddingLeft: 2,
						paddingRight: 2,
						height: '100%'
					}}
				>
					<Box
						component="img"
						src={SoraLogoBlack}
						sx={{ height: 25 }}
					/>

					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						onClick={() => toggleMenuOpen()}
					>
						{!isMenuOpen ? (
							<MenuIcon sx={{ color: 'black' }} />
						) : (
							<CloseIcon sx={{ color: 'black' }} />
						)}
					</IconButton>
				</Toolbar>

				<Nav isMenuOpen={isMenuOpen} toggleMenuOpen={toggleMenuOpen} />
			</AppBar>
		</>
	);
}
