import { v4 as uuidv4 } from 'uuid';
import numbro from 'numbro';

// Constants
import { AUTO, STUDENT, PERSONAL } from 'shared/constants';

/**
 *
 * @param {string | number | boolean | null | undefined} data
 * @returns {boolean}
 */
export const dataIsValid = (data) => data !== undefined && data !== null;

/**
 *
 * @param {string} email a string representation of an email
 * @returns {boolean}
 */
export const isValidEmail = (email) =>
	email === '' || !email.includes('.') || !email.includes('@');

export const isFormValueReady = (inputData) => inputData !== ''; // Returns false if value is empty
export const isSubmissionReady = (formData) => formData.every(isFormValueReady);

export const convertMonthsToYears = (months, roundedToDecimal = 2) =>
	roundToDecimal(months / 12, roundedToDecimal) ?? months;

export const generateRandomEmail = (firstName, lastName) => {
	// it will be contact+

	const firstPartOfEmail = `contact+${firstName.replaceAll(
		' ',
		''
	)}${lastName.replaceAll(' ', '')}`;

	const secondPart = uuidv4();

	return `${firstPartOfEmail}${secondPart}@sorafinance.com`;
};

/**
 *
 * @param {number} number
 * @param {number} toDecimalPoint
 * @returns {number}
 */

export const roundToDecimal = (number, toDecimalPoint) => {
	const convertedNumber = numbro(number).format({
		thousandSeparated: true,
		mantissa: toDecimalPoint
	});

	if (Number.isNaN(convertedNumber)) {
		throw new Error(
			`${number} is not a number and therefore cannot be roundToDecimal`
		);
	}
	return convertedNumber;
};

// This will format a number to currency in USD
// It will round the fraction units to 0 meaning no decimal point will be used.
// e.g. 45000 -> 45,000
export const dollarFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	maximumFractionDigits: 0
});

export const dateToIsoFormat = (date) => {
	// date is expected in YYYY-MM-DD format
	const dateObj = new Date(date);

	return dateObj.toISOString();
};

// This maps the dropdown options with what the BE expects
// lefthand side is what the UI shows and the right are the values expected by the BE
export const EMUM_TO_FINANCIAL_GOAL_MAPPING = (incomingEnum) => {
	const ENUM_TO_GOALS_MAPPING = {
		LOW_EMI: 'Reduce my monthly payments',
		LOW_COD: 'Reduce total cost of debt',
		EARLY_PAYOFF: 'Pay off my loans faster',
		CASH_OUT: 'Cash out',
		'OTHER PRIORITY': ''
	};

	return ENUM_TO_GOALS_MAPPING[incomingEnum];
};

// This maps the dropdown options with what the BE expects
// lefthand side is what the UI shows and the right are the values expected by the BE
export const FINANCIAL_GOAL_TO_ENUM_MAPPING = (goal) => {
	const GOALS_TO_ENUM_MAPPING = {
		'Reduce my monthly payments': 'LOW_EMI',
		'Reduce total cost of debt': 'LOW_COD',
		'Pay off my loans faster': 'EARLY_PAYOFF',
		'Cash out': 'CASH_OUT'
	};

	return GOALS_TO_ENUM_MAPPING[goal];
};

// loanType is actually tradeLineType which comes in STUDENT | MORTGAGE | HELOC | AUTO
// We want to convert those enoms to Student | Mortgage | Heloc | Auto | etc
export const normalizeLoanType = (tradelineType) =>
	tradelineType && tradelineType[0] + tradelineType.slice(1).toLowerCase();

const DEFAULT_YEAR_TO_ADD = 20; // This is the default for Mortgage
const DEFAULT_AUTO_YEAR = 3;
const DEFAULT_PERSONAL_YEAR = 1;
const DEFAULT_STUDENT_YEAR = 5;

export function getDefaultApproxPayoffDate(liability) {
	// gets the current date
	const today = new Date(Date.now());

	// gets the current year
	const currentYear = today.getFullYear();

	// variable that tracks the value to add to year
	let additionToCurrentYear = DEFAULT_YEAR_TO_ADD;

	// based on the liability it will get ITS default year
	// i.e. for Auto if today is Dec 2, 2022 it will add 3 years to it. Making it Dec, 2 2025
	if (liability === AUTO) additionToCurrentYear = DEFAULT_AUTO_YEAR;
	if (liability === STUDENT) additionToCurrentYear = DEFAULT_STUDENT_YEAR;
	if (liability === PERSONAL) additionToCurrentYear = DEFAULT_PERSONAL_YEAR;

	today.setFullYear(currentYear + additionToCurrentYear);

	return today;
}

export function getNonNullValues(objectToCleanse) {
	const objectWithoutNullValues = {};
	// works only with Objects that are not nested. i.e. must be Flat
	// eslint-disable-next-line array-callback-return
	Object.keys(objectToCleanse).map((currentKey) => {
		const key = currentKey;

		const currentValue = objectToCleanse[key];
		const isValid = currentValue !== null && currentValue !== undefined;

		if (isValid) objectWithoutNullValues[key] = currentValue;
	});

	// objectWithoutNullValues is the same as the objectToCleanse as in key value pairs
	// except it is filtered. No values of null will be present
	return objectWithoutNullValues;
}

// This maps the dropdown options with what the BE expects
// lefthand side is what the UI shows and the right are the values expected by the BE
export const PROPERTY_TYPES_TO_ENUM_MAPPING = (propertyType) => {
	const PROPERTY_TO_ENUM_MAPPING = {
		Primary: 'PRIMARY',
		'Secondary/Vacation': 'SECONDARY',
		Rental: 'INVESTMENT'
	};

	return PROPERTY_TO_ENUM_MAPPING[propertyType];
};

export const capitalizeWord = (word) =>
	word[0].toUpperCase() + word.slice(1).toLowerCase();
