import { useMutation } from 'react-query';

// our axios
import axiosInstance from 'services/API/API';

// Our urls
import { userOnboardingStatus as updateUserOnboardingStatus } from 'shared/api-urls';

function useMutateUpdateOnboardingStatus() {
	return useMutation(async (onboardingStatus) => {
		const updateUserOnboardingStatusUrl = `${updateUserOnboardingStatus}${onboardingStatus}`;
		const response = await axiosInstance.post(
			updateUserOnboardingStatusUrl,
			{}
		);

		return response.data;
	});
}

export default useMutateUpdateOnboardingStatus;
