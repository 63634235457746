import { useMutation } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoint urls
import { getEmailFromHashEndpoint } from 'shared/api-urls';

function useMutateGetHash() {
	const { token } = useParams();
	const navigate = useNavigate();

	return useMutation(
		async () => {
			const response = await axiosInstance.get(
				`${getEmailFromHashEndpoint}${token}`
			);

			return response.data.data;
		},
		{
			onError: (error) => {
				navigate('/404');
				console.error('Error when trying to mask account', error);
			}
		}
	);
}

export default useMutateGetHash;
