import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Api URLS
import { getUserLiabilitiesUrl } from 'shared/api-urls';

// QUery keys
import { USER_AUTOS } from 'shared/query-keys';

// Our utils
import { getNonNullValues } from 'shared/utils';

function useGetUserAutoLiability() {
	const { tradeLineId } = useParams();

	const urlWithTradeLineId = `${getUserLiabilitiesUrl}${tradeLineId}`;

	return useQuery(
		[USER_AUTOS, tradeLineId],
		async () => {
			const response = await axiosInstance.get(urlWithTradeLineId);

			// This is the data from Sora Api which includes meta
			const Liability = response.data;

			// This is the actual mortgage Data
			const autoData = Liability.data;

			return autoData;
		},
		{
			refetchOnWindowFocus: false,
			select: ({
				expectedPayOffDate,
				financialGoal,
				interestRate,
				lender,
				loanType,
				monthlyPay,
				outstandingBalance,
				tenureMonth,
				vehicleInfo
			}) => {
				// get rid of garbage data before giving it to AutoLiability Component
				const autoDataWithoutNullValues = getNonNullValues({
					expectedPayOffDate,
					financialGoal,
					interestRate,
					lender,
					loanType,
					monthlyPay,
					outstandingBalance,
					tenureMonth,
					vehicleInfo
				});
				return autoDataWithoutNullValues;
			}
		}
	);
}

export default useGetUserAutoLiability;
