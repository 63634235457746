import { useQuery } from 'react-query';

// Our Endpoints
import { walletOverviewUrl } from 'shared/api-urls';

// Our Query Keys
import { WALLET_OVERVIEW_DATA } from 'shared/query-keys';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Constants
const FIVE_MINUTES = 1000 * 60 * 5;

function useGetWalletOverview() {
	const endpointBasedOnClientId = walletOverviewUrl;

	return useQuery(
		WALLET_OVERVIEW_DATA,
		async () => {
			const response = await axiosInstance.get(endpointBasedOnClientId);
			const clientWalletOverviewData = response.data.data;
			return clientWalletOverviewData;
		},
		{
			staleTime: FIVE_MINUTES
		}
	);
}

export default useGetWalletOverview;
