import { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';
import FinancialGoalDropdown from 'components/Dropdown/FinancialGoalDropdown';
import FormButton from 'components/Forms/FormButton';
import GetFullAddressPartialForm from 'components/Forms/GetFullAddressPartialForm';
import HomeLoanType from 'components/Dropdown/HomeLoanType';
import LiabilityFormHeading from 'components/Forms/Liabilities/LiabilityFormHeading';
import Loader from 'components/Loader';
import OutstandingBalanceInput from 'components/Input/OutstandingBalanceInput';
import MonthlyPaymentInput from 'components/Input/MonthlyPaymentInput';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import { TEXT } from 'components/Input/Types';
import RateInput from 'components/Input/RateInput';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import TextInput from 'components/Input/TextInput';

// Utils
import {
	dataIsValid,
	dateToIsoFormat,
	FINANCIAL_GOAL_TO_ENUM_MAPPING,
	getDefaultApproxPayoffDate,
	EMUM_TO_FINANCIAL_GOAL_MAPPING as inverseFinancialGoalMapping,
	isSubmissionReady,
	normalizeLoanType,
	PROPERTY_TYPES_TO_ENUM_MAPPING,
	roundToDecimal
} from 'shared/utils';

// Hooks
import useGetUserMortgageLiability from 'hooks/client/liabilities/queries/useGetUserMortgageLiability';
import useMutateSaveMortgageLiability from 'hooks/client/liabilities/mutations/useMutateSaveMortgageLiability';

// Constants
import { MORTGAGE } from 'shared/constants';

// Supported Loan Tenures
import LoanTenureType from 'components/Dropdown/LoanTenureType.json';

const TYPES_OF_HOME_PROPERTIES = ['Primary', 'Secondary/Vacation', 'Rental'];

const PROPERTY_OWNERSHIP_TYPES = [
	'0-4 years',
	'5-7 years',
	'8-14 years',
	'15-29 years',
	'30+ years'
];

const HOME_PROPERTIES_TO_SKIP = new Set([
	'id',
	'assedValue',
	'currency',
	'marketValue',
	'assedValue'
]);

const DEFAULT_LOAN_INFO = {
	loanTerm: '30-year',
	loanType: 'Fixed'
};

// Helper fns
const getDefaultLoanType = (loanTypeToCheck) => {
	if (loanTypeToCheck === undefined || loanTypeToCheck === null)
		return 'Fixed';
	return loanTypeToCheck === 'fixed' ? 'Fixed' : 'ARM';
};

const convertTenureLengthToRecognizedFormat = (
	tenureLengthInMonths,
	loanType
) => {
	const getDefaultTenureType = () =>
		loanType === 'Fixed' ? '30-year' : '5-year';

	if (tenureLengthInMonths === undefined || tenureLengthInMonths === null)
		return getDefaultTenureType();

	try {
		const calculatedTenureLength = Math.floor(tenureLengthInMonths / 12);
		const calculatedNormalizedTenureLength = `${calculatedTenureLength}-year`;
		const isValidTenureType = LoanTenureType[loanType].includes(
			calculatedNormalizedTenureLength
		);

		// If the calculated tenureType is in the list of accepted tenure types
		// pre populate it
		if (isValidTenureType) {
			return calculatedNormalizedTenureLength;
		}
		// if its not in the default
		return getDefaultTenureType();
	} catch (e) {
		// if something went wrong calculating this just return the default.
		return getDefaultTenureType();
	}
};

function HomeLiability() {
	// these defaults are based on Methodfi Response
	const navigate = useNavigate();
	const { tradeLineId } = useParams();
	const { isLoading, data, isSuccess } = useGetUserMortgageLiability();
	const saveMortgage = useMutateSaveMortgageLiability();

	const { isLoading: isMutationLoading } = saveMortgage;

	// Mortgage Liability Related
	const [approxPayoffDate, setApproxPayoffDate] = useState(
		getDefaultApproxPayoffDate(MORTGAGE)
	);

	const [lender, setLender] = useState('');
	const [loanInfo, setLoanInfo] = useState(DEFAULT_LOAN_INFO);
	const [monthlyPayment, setMonthlyPayment] = useState('');
	const [escrow, setEscrow] = useState('0');
	const [outstandingBalance, setOutstandingBalance] = useState(''); // balance / 100 is bc methodfi provides balance in cents so we divide by 100 to convert into dollars
	const [rate, setRate] = useState('');

	// Address Related
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [city, setCity] = useState('');
	const [propertyType, setPropertyType] = useState('Primary'); // this refers to Loan Type field
	const [propertyOwnershipTenure, setPropertyOwnershipTenure] =
		useState('15-29 years'); // how long do you intend to own this home ?!
	const [state, setState] = useState('');
	const [zipCode, setZipCode] = useState('');

	// Financial Goal
	const [financialGoal, setFinancialGoal] = useState(
		'Reduce total cost of debt'
	);

	// separate
	const [disableAutoComplete, setDisableAutoComplete] = useState(false);

	useEffect(() => {
		if (isSuccess) {
			setDisableAutoComplete(true);
			const WANTED_KEY_SET_MAPPING = {
				addressLine1: setAddressLine1,
				addressLine2: setAddressLine2,
				city: setCity,
				expectedPayOffDate: setApproxPayoffDate,
				escrow: setEscrow,
				financialGoal: setFinancialGoal,
				interestRate: setRate,
				lender: setLender,
				loanType: setLoanInfo,
				monthlyPay: setMonthlyPayment,
				outstandingBalance: setOutstandingBalance,
				ownershipTenure: setPropertyOwnershipTenure,
				propertyType: setPropertyType,
				propertyOwnershipTenure: setPropertyOwnershipTenure,
				state: setState,
				tenureMonth: setLoanInfo,
				zipcode: setZipCode
			};

			const homeLiabilityFields = Object.keys(data);

			try {
				homeLiabilityFields.forEach((homeLiabilityField) => {
					const currentData = data[homeLiabilityField];
					const setUpdater =
						WANTED_KEY_SET_MAPPING[homeLiabilityField];

					if (homeLiabilityField === 'tenureMonth') return;

					if (homeLiabilityField === 'clientHome') {
						// data in this field is nested so we gotta iterate over that.
						const mortgageSpecificFields = Object.keys(currentData);

						mortgageSpecificFields.forEach(
							(mortgageSpecificField) => {
								// assedValue is not used so skip it.
								if (
									HOME_PROPERTIES_TO_SKIP.has(
										mortgageSpecificField
									)
								)
									return;

								const mortgageSpecificUpdater =
									WANTED_KEY_SET_MAPPING[
										mortgageSpecificField
									];
								const mortgageSpecificData =
									currentData[mortgageSpecificField];

								// This data is may or may not be null
								if (!dataIsValid(mortgageSpecificData)) return;
								if (mortgageSpecificField === 'propertyType') {
									const formattedPropertyType =
										normalizeLoanType(mortgageSpecificData);
									mortgageSpecificUpdater(
										formattedPropertyType
									);
									return;
								}
								mortgageSpecificUpdater(mortgageSpecificData);
							}
						);

						return;
					}

					if (
						homeLiabilityField === 'monthlyPay' ||
						homeLiabilityField === 'outstandingBalance' ||
						homeLiabilityField === 'interestRate' ||
						homeLiabilityField === 'escrow'
					) {
						// Here the values are ints we need to convert them to strings

						if (homeLiabilityField === 'escrow') {
							const isNegative = currentData < 0;

							if (isNegative) return;
						}
						setUpdater(`${currentData}`);
						return;
					}

					if (homeLiabilityField === 'loanType') {
						const storedLoanTerm = data?.tenureMonth;
						const storedLoanType = data?.loanType;
						if (storedLoanTerm && storedLoanType) {
							const normalLoanType = getDefaultLoanType(
								currentData.toLowerCase()
							);
							const loanTermInYears =
								convertTenureLengthToRecognizedFormat(
									storedLoanTerm,
									normalLoanType
								);

							setUpdater({
								loanTerm: loanTermInYears,
								loanType: normalLoanType
							});
							return;
						}

						return;
					}

					if (homeLiabilityField === 'financialGoal') {
						setUpdater(inverseFinancialGoalMapping(currentData));
						return;
					}
					setUpdater(currentData);
				});
			} catch (e) {
				console.error(e);
			}
		}
	}, [data]);

	useEffect(
		() => () => {
			setAddressLine1('');
			setAddressLine2('');
			setCity('');
			setState('');
			setZipCode('');
		},
		[tradeLineId]
	);

	const formValues = [
		addressLine1,
		approxPayoffDate,
		city,
		financialGoal,
		lender,
		loanInfo.loanTerm,
		loanInfo.loanType,
		monthlyPayment,
		outstandingBalance,
		propertyType,
		propertyOwnershipTenure,
		rate,
		escrow,
		state,
		zipCode
	];

	const isFormReady = useMemo(
		() => isSubmissionReady(formValues) && approxPayoffDate !== null,
		formValues
	);

	const handleApproxPayoffDateChange = (value) => {
		const isValueEmpty = value === '';
		if (isValueEmpty) {
			setApproxPayoffDate('');
			return;
		}
		setApproxPayoffDate(value);
	};

	const submitLiabilityData = (route) => {
		const formattedApproxPayOffDate = dateToIsoFormat(approxPayoffDate);
		const formattedFinancialGoal =
			FINANCIAL_GOAL_TO_ENUM_MAPPING(financialGoal);
		const { loanTerm, loanType } = loanInfo;
		const formattedLoanType = loanType.toUpperCase();
		const formattedPropertyType =
			PROPERTY_TYPES_TO_ENUM_MAPPING(propertyType);
		const formattedRate = roundToDecimal(+rate, 2);
		const strippedOwnershipTenure = loanTerm?.split('-')[0];

		const homeData = {
			addressLine1,
			addressLine2,
			approxPayoffDate: formattedApproxPayOffDate,
			city,
			escrow,
			financialGoal: formattedFinancialGoal,
			lender,
			loanType: formattedLoanType,
			monthlyPayment: +monthlyPayment,
			outstandingBalance: +outstandingBalance,
			propertyType: formattedPropertyType,
			propertyOwnershipTenure,
			rate: formattedRate,
			state,
			tenure: strippedOwnershipTenure,
			tradelineType: 'MORTGAGE',
			zipcode: zipCode
		};

		saveMortgage.mutate(homeData, {
			onSuccess: () => {
				navigate(route);
			}
		});
	};

	if (isLoading)
		return (
			<>
				<Helmet>
					<title>Home Loan</title>
				</Helmet>
				<LiabilityFormHeading headingText="Home Loan" />
				<Loader
					size={60}
					boxSX={{ alignItems: 'center', marginTop: 15 }}
				/>
			</>
		);

	return (
		<>
			<Helmet>
				<title>Home Loan</title>
			</Helmet>

			{/* Once data binding begins we will add Mortgage specific information to this header */}
			<LiabilityFormHeading headingText="Home Loan" />

			<Grid container spacing={2} sx={{ marginLeft: { sm: 10, xs: 0 } }}>
				<Grid
					item
					xs={12}
					sm={11}
					md={5}
					lg={4}
					sx={{ marginLeft: 1, marginRight: 1 }}
				>
					<OutstandingBalanceInput
						outstandingBalance={outstandingBalance}
						setOutstandingBalance={setOutstandingBalance}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sm={11}
					md={5}
					lg={4}
					sx={{ marginLeft: 1, marginRight: 1 }}
				>
					<RateInput rate={rate} setRate={setRate} />
				</Grid>

				{/* Uses 10 Grid Columns and has marginBottom 4 */}
				<HomeLoanType loanInfo={loanInfo} setLoanInfo={setLoanInfo} />

				<Grid
					item
					xs={12}
					sm={11}
					md={5}
					lg={4}
					sx={{ marginLeft: 1, marginRight: 1 }}
				>
					<TextInput
						type={TEXT}
						label="Lender"
						subLabel="Enter the name of your lender"
						value={lender}
						onChange={setLender}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={11}
					md={5}
					lg={4}
					sx={{ marginLeft: 1, marginRight: 1 }}
				>
					<MonthlyPaymentInput
						monthlyPayment={monthlyPayment}
						outstandingBalance={outstandingBalance}
						setMonthlyPayment={setMonthlyPayment}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={11}
					md={5}
					lg={4}
					sx={{ marginLeft: 1, marginRight: 1 }}
				>
					<CurrencyTextInput
						label="Escrow (Monthly)"
						onChange={setEscrow}
						value={escrow}
						subLabel="Optional"
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={11}
					md={5}
					lg={4}
					sx={{ marginLeft: 1, marginRight: 1 }}
				>
					<StandardDatePicker
						label="Expected Payoff Date"
						helperText="Enter the approximate payoff date"
						onChange={handleApproxPayoffDateChange}
						value={approxPayoffDate}
						error={approxPayoffDate === ''}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={11}
					md={5}
					lg={4}
					sx={{ marginLeft: 1, marginRight: 1 }}
				>
					<Dropdown
						items={TYPES_OF_HOME_PROPERTIES}
						selected={propertyType}
						onChange={setPropertyType}
						variant="outlined"
						label="Type of property"
					/>
				</Grid>

				<FinancialGoalDropdown
					financialGoal={financialGoal}
					setFinancialGoal={setFinancialGoal}
				/>

				<Grid
					item
					xs={12}
					sm={11}
					md={5}
					lg={4}
					sx={{ marginLeft: 1, marginRight: 1 }}
				>
					<Dropdown
						items={PROPERTY_OWNERSHIP_TYPES}
						selected={propertyOwnershipTenure}
						onChange={setPropertyOwnershipTenure}
						variant="outlined"
						label="Intended ownership tenure (years)"
						labelSx={{
							whiteSpace: 'nowrap'
						}}
					/>
				</Grid>

				<GetFullAddressPartialForm
					addressLine1={addressLine1}
					addressLine2={addressLine2}
					city={city}
					state={state}
					zipCode={zipCode}
					setAddressLine1={setAddressLine1}
					setAddressLine2={setAddressLine2}
					setCity={setCity}
					setState={setState}
					setZipCode={setZipCode}
					disableAutoComplete={disableAutoComplete}
					setDisableAutoComplete={setDisableAutoComplete}
				/>

				<FormButton
					isFormReady={isFormReady}
					isMutationLoading={isMutationLoading}
					submitLiabilityData={submitLiabilityData}
				/>
			</Grid>
		</>
	);
}

export default HomeLiability;
