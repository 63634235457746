import { useMutation } from 'react-query';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoint urls
import { maskAsAnotherUser, userLogin as userLoginUrl } from 'shared/api-urls';

// Other Hooks
import useMutateGetHash from 'hooks/admin/useMutateGetHash';

function useMutateAddUserMask() {
	const auth = getAuth();

	const getUserHash = useMutateGetHash();

	return useMutation(
		async (accountToMimicData) => {
			const firebaseResponse = await signInWithEmailAndPassword(
				auth,
				process.env.REACT_APP_ADMIN_USER,
				process.env.REACT_APP_ADMIN_KEY
			);

			const firebaseIdToken = firebaseResponse.user.accessToken;

			const loginResponse = await axiosInstance.post(userLoginUrl, {
				firebaseIdToken
			});

			// get Advisor Token here.
			const userProfileResponse = await getUserHash.mutateAsync({});

			const userEmail = userProfileResponse?.email ?? '';

			const response = await axiosInstance.post(maskAsAnotherUser, {
				...accountToMimicData,
				userEmail
			});

			const mockedAccountData = response.data;

			return mockedAccountData;
		},
		{
			onError: (error) => {
				console.error('Error when trying to mask account', error);
			}
		}
	);
}

export default useMutateAddUserMask;
