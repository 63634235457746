// Utils
import { getDefaultApproxPayoffDate } from 'shared/utils';

// APP CONSTANTS
import { AUTO } from 'shared/constants';

function createDefaultAutoUpdatePayload(autoLiability) {
	const {
		lender,
		monthlyPay,
		outstandingBalance,
		interestRate,
		tenureMonth
	} = autoLiability;

	const approxPayoffDate = getDefaultApproxPayoffDate(AUTO);

	const autoUpdatePayload = {
		approxPayoffDate,
		condition: 'Excellent',
		financialGoal: 'LOW_COD',
		lender,
		licensePlate: '',
		loanType: 'FIXED',
		mileage: '1',
		monthlyPayment: monthlyPay,
		outstandingBalance,
		rate: interestRate,
		tradelineType: AUTO,
		tenure: +tenureMonth,
		state: '',
		vin: ''
	};

	return autoUpdatePayload;
}

export default createDefaultAutoUpdatePayload;
