import { useCallback } from 'react';
import PropTypes from 'prop-types';

// Our Components
import TextInput from 'components/Input/TextInput';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

// Input types
import { PERCENT } from 'components/Input/Types';

function RateInput({ rate, setRate, sx, label, subLabel }) {
	const handleRateChange = useCallback((value) => {
		const isValueEmpty = value === '';
		if (isValueEmpty) {
			setRate('');
			return;
		}
		const number = +value;
		const isWithinRange = number >= 0 && number <= 100;
		const hasDecimal = value.includes('.');
		if (!hasDecimal && value && value.length > 3) return;
		const decimalIndex = value.indexOf('.');

		if (isWithinRange) {
			const startsWithZero =
				value && value.length > 1 && value[0] === '0';
			if (hasDecimal) {
				const numbersBeforeDecimal = value.slice(0, decimalIndex);
				if (numbersBeforeDecimal.length >= 2) {
					const isAllZeroesBeforeDecimal = numbersBeforeDecimal
						.split('')
						.every((char) => char === '0');
					if (isAllZeroesBeforeDecimal) return;
				}

				const numbersAfterDecimal = value.slice(
					decimalIndex + 1,
					value.length
				);
				if (numbersAfterDecimal.length > 3) return;
			} else if (startsWithZero) {
				const isOnlyZeroes = value
					.split('')
					.every((char) => char === '0');
				if (isOnlyZeroes) return;
			}
			setRate(value);
		}
	}, []);

	return (
		<TextInput
			label={label}
			onChange={handleRateChange}
			subLabel={subLabel}
			sx={sx}
			type={PERCENT}
			value={rate}
		/>
	);
}

RateInput.propTypes = {
	rate: PropTypes.string.isRequired,
	setRate: PropTypes.func.isRequired,
	sx: CommonPropTypes.sx,
	label: PropTypes.string,
	subLabel: PropTypes.string
};

RateInput.defaultProps = {
	sx: {},
	label: 'Rate',
	subLabel: 'Estimate is fine'
};

export default RateInput;
