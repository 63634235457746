import { useMutation } from 'react-query';

// Our axios instance
import axiosInstance from 'services/API/API';

// Endpoints
import { userIncomeEndpoint } from 'shared/api-urls';

// Additional useMutation
import useMutateUpdateOnboardingStatus from 'hooks/useMutateUpdateOnboardingStatus';

// Our Constants
import { INCOME_UPDATED } from 'shared/constants';

function useMutateSetIncome() {
	const updateOnboardingStatus = useMutateUpdateOnboardingStatus();

	return useMutation(async ({ totalOtherAnnualIncome, otherIncomeId }) => {
		const payload = {
			compensationAmount: totalOtherAnnualIncome,
			compensationUnit: 'annually',
			compensationCurrency: 'USD'
		};

		const apiMethodUsed = otherIncomeId ? 'patch' : 'post'; // PATCH if id passed. Otherwise POST without id

		const urlBasedOnIncomeId = `${userIncomeEndpoint}/${
			otherIncomeId ?? ''
		}`;

		const response = await axiosInstance[apiMethodUsed](
			urlBasedOnIncomeId,
			payload
		);

		const userIncomeData = response.data.data;

		// variable here is not needed but the AWAIT is.
		// stored in a variable for contextual purposes.
		const makeOnboardingStatusUpdateResponse =
			await updateOnboardingStatus.mutate(INCOME_UPDATED);

		return userIncomeData;
	});
}

export default useMutateSetIncome;
