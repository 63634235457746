/**
 * @param {Date} date Date Object
 * @returns {string} yyyy-MM-dd string
 */

function convertDateToEndpointFormat(date) {
	try {
		// Digesting the DOB from a Date string to a YYY-MM-DD format need for endpoint
		// Using the 'ko-KR' locale within toLocaleDateString outputs
		// the date in the correct order. (ie. YYYY. MM. DD.)
		const parsedDob = date.toLocaleDateString('ko-KR', {
			year: 'numeric',
			day: '2-digit',
			month: '2-digit'
		});
		// parsedDob looks like this (YYYY. MM. DD.)

		// Strip out all the ('. ') characters and replace with ('-')
		const cleanedDob = parsedDob && parsedDob.replaceAll('. ', '-');
		// cleanedDob looks like this (YYYY-MM-DD.)

		// Remove the last ('.') character from the string
		const dobYearMonthDayFormat = cleanedDob.slice(0, -1);

		return dobYearMonthDayFormat;
	} catch (e) {
		return '';
	}
}

export default convertDateToEndpointFormat;
