import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Api URLS
import { getUserLiabilitiesUrl } from 'shared/api-urls';

// QUery keys
import { USER_STUDENTS } from 'shared/query-keys';

// Our utils
import { getNonNullValues } from 'shared/utils';

function useGetUserStudentLiability() {
	const { tradeLineId } = useParams();
	// if this is a isManualRoute do not do the data binding

	const urlWithTradeLineId = `${getUserLiabilitiesUrl}${tradeLineId}`;

	return useQuery(
		[USER_STUDENTS, tradeLineId],
		async () => {
			const response = await axiosInstance.get(urlWithTradeLineId);

			// This is the data from Sora Api which includes meta
			const Liability = response.data;

			// This is the actual mortgage Data
			const studentLiabilityData = Liability.data;
			return studentLiabilityData;
		},
		{
			refetchOnWindowFocus: false,
			select: ({
				expectedPayOffDate,
				financialGoal,
				interestRate,
				lender,
				lenderType,
				loanType,
				monthlyPay,
				outstandingBalance,
				tenureMonth,
				studentInfo
			}) => {
				// get rid of garbage data before giving it to AutoLiability Component
				const studentDataWithoutNullValues = getNonNullValues({
					expectedPayOffDate,
					financialGoal,
					interestRate,
					lender,
					lenderType,
					loanType,
					monthlyPay,
					outstandingBalance,
					tenureMonth,
					studentInfo
				});
				return studentDataWithoutNullValues;
			}
		}
	);
}

export default useGetUserStudentLiability;
