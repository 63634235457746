// Utility fn
import {
	EMAIL,
	NUMBER,
	PASSWORD,
	PERCENT,
	PRICE,
	TEL,
	TEXT
} from 'components/Input/Types';

/*
@param {string} type
*/
export const isValidTextInput = (type) => {
	// isValid if any of these
	const VALIDTYPES = new Set([
		EMAIL,
		NUMBER,
		PASSWORD,
		PERCENT,
		PRICE,
		TEXT,
		TEL
	]);
	return VALIDTYPES.has(type);
};

// if you want to check if its a specific value
export const isPercentInput = (type) => type === PERCENT;
export const isPriceInput = (type) => type === PRICE;

// if you want to get a valid type.
export const getInputType = (type) => {
	const VALIDTYPES = {
		email: EMAIL,
		number: NUMBER,
		password: PASSWORD,
		percent: NUMBER,
		price: NUMBER,
		tel: TEL,
		text: TEXT
	};

	if (!(type in VALIDTYPES)) {
		// eslint-disable-next-line no-console
		console.error(`type ${type} is not in VALIDTYPES`);
	}

	return VALIDTYPES[type];
};
