import { Grid, Typography } from '@mui/material/index';

// Our Components
import LiabilitiesLoading from 'components/Liabilities/LiabilitiesLoading';

function LiablitiesProcessing() {
	return (
		<>
			<Grid item xs={12} sx={{ marginTop: 4, marginLeft: 2 }}>
				<Typography variant="h4">
					Please Do Not Refresh The Page
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography
					variant="body1"
					component="div"
					sx={{
						paddingLeft: 2,
						marginTop: 4,
						marginBottom: 2
					}}
				>
					Sora is working to display and analyze your existing debt.
				</Typography>
			</Grid>
			<LiabilitiesLoading />
		</>
	);
}

export default LiablitiesProcessing;
