import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

// CONSTANTS
const LOAN_TYPES = ['Fixed', 'Variable'];

function LoanTypeDropdown({
	dropDownSX,
	dropDownLabelSX,
	sx,
	loanType,
	setLoanType
}) {
	return (
		<Grid
			item
			xs={12}
			sm={11}
			md={5}
			lg={4}
			sx={{ marginLeft: 1, marginRight: 1, ...sx }}
		>
			<Dropdown
				items={LOAN_TYPES}
				label="Loan Type"
				labelSx={dropDownLabelSX}
				onChange={setLoanType}
				selected={loanType}
				sx={dropDownSX}
				variant="outlined"
			/>
		</Grid>
	);
}

LoanTypeDropdown.propTypes = {
	dropDownSX: CommonPropTypes.sx,
	dropDownLabelSX: CommonPropTypes.sx,
	gridColumns: PropTypes.number,
	loanType: PropTypes.string.isRequired,
	setLoanType: PropTypes.func.isRequired,
	sx: CommonPropTypes.sx
};

LoanTypeDropdown.defaultProps = {
	dropDownLabelSX: {},
	dropDownSX: {},
	gridColumns: 5,
	sx: {}
};

export default LoanTypeDropdown;
