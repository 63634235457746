import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

// Our Prop Types
import CommonPropTypes from 'shared/prop-types';
import { TEL } from 'components/Input/Types';

// Our Components
import TextInput from 'components/Input/TextInput';

// Our Utils
import formatPhoneNumber from 'shared/formatting/formatPhoneNumber';

// Constants
import PhoneNumberWhitelist from 'shared/phone-number-whitelist.json';

function PhoneNumberInput({
	isValidPhoneNumber,
	inputProps,
	onChange,
	phoneNumber,
	subLabel,
	sx
}) {
	const onChangeHandler = useCallback(
		(incomingPhoneNumber) => {
			const isIncomingPhoneNumberEmpty = incomingPhoneNumber === '';
			if (isIncomingPhoneNumberEmpty) {
				onChange('');
				return;
			}

			const specialMethodfiCheck = incomingPhoneNumber?.replaceAll(
				'-',
				''
			);

			// eslint-disable-next-line no-restricted-syntax
			for (const methodfiWhiteListedPhoneNumber of PhoneNumberWhitelist) {
				if (
					methodfiWhiteListedPhoneNumber.includes(
						specialMethodfiCheck
					)
				) {
					onChange(specialMethodfiCheck);
					return;
				}
			}

			const isMaxLength = incomingPhoneNumber.length > 12;

			if (isMaxLength) return;

			const checkLastValue =
				incomingPhoneNumber[incomingPhoneNumber.length - 1] === '-';

			if (checkLastValue) {
				const phoneNumberWithoutHyphen = incomingPhoneNumber.slice(
					0,
					incomingPhoneNumber.length - 1
				);
				onChange(phoneNumberWithoutHyphen);
				return;
			}

			const formattedPhoneNumber = formatPhoneNumber(incomingPhoneNumber); // format the string and go ham

			onChange(formattedPhoneNumber);
		},
		[phoneNumber]
	);

	const getPhoneErrorMessage = useMemo(() => {
		if (phoneNumber === '') return 'Phone number cannot be empty';
		if (!isValidPhoneNumber) return 'Phone number is not valid US number';
		return null;
	}, [phoneNumber]);

	return (
		<TextInput
			id="phoneNumber"
			label="Phone Number"
			type={TEL}
			onChange={onChangeHandler}
			subLabel={subLabel}
			value={phoneNumber}
			placeholder="123-456-7890"
			error={phoneNumber === '' || !isValidPhoneNumber}
			helperText={getPhoneErrorMessage}
			inputProps={inputProps}
			sx={sx}
		/>
	);
}

PhoneNumberInput.propTypes = {
	inputProps: PropTypes.oneOfType([PropTypes.object]),
	onChange: PropTypes.func.isRequired,
	phoneNumber: PropTypes.string.isRequired,
	subLabel: PropTypes.string,
	sx: CommonPropTypes.sx
};

PhoneNumberInput.defaultProps = {
	inputProps: {},
	subLabel: 'Enter your personal phone number',
	sx: {}
};

export default PhoneNumberInput;
