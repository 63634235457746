import { useMutation } from 'react-query';
import axios from 'axios';

// Constants
const ONBOARDING_SERVICE_API_KEY =
	process.env.REACT_APP_ONBOARDING_SERVICE_API_KEY;
const ONBOARDING_SERVICE_URL = process.env.REACT_APP_ONBOARDING_SERVICE_URL;

function useMutateSendAccountCreationLog() {
	return useMutation(async (logPayload) => {
		// Wrapping log creation in a try catch to prevent the mutation from failing
		await axios.post(`${ONBOARDING_SERVICE_URL}/log/create`, logPayload, {
			headers: {
				'Content-Type': 'application/json',
				'X-API-KEY': ONBOARDING_SERVICE_API_KEY
			}
		});
	});
}

export default useMutateSendAccountCreationLog;
