import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport
} from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PropTypes from 'prop-types';

// Our utils
import { dataIsValid } from 'shared/utils';

function CustomToolbar() {
	return (
		<GridToolbarContainer
			sx={{
				display: 'flex',
				justifyContent: 'flex-end',
				marginBottom: 2
			}}
		>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarExport />
		</GridToolbarContainer>
	);
}

// TODO @Daniel add Prop Types to specify the shape of columns, and rows
function DataTable(props) {
	const SoraTheme = useTheme();

	const {
		columns,
		enableToolbar,
		initialSortFieldName,
		pageSize,
		rows,
		rowsPerPageOptions,
		withRowColorCheck
	} = props;
	// TODO Add aria label to Mui Buttons
	//
	return (
		// TODO @Daniel height shouldn't be a specific px value. See if we can make it responsive.
		<DataGrid
			rowHeight={62}
			rows={rows}
			columns={columns}
			initialState={{
				sorting: {
					sortModel: [{ field: initialSortFieldName, sort: 'asc' }]
				}
			}}
			pageSize={pageSize} // example is 5
			rowsPerPageOptions={[rowsPerPageOptions]}
			getRowClassName={(params) => {
				if (withRowColorCheck) {
					const hasStatusDataInRow = dataIsValid(params.row?.status);

					if (hasStatusDataInRow && params?.row.status !== 'ACTIVE')
						return 'MuiDataGrid-row-special';
				}

				return 'MuiDataGrid-row';
			}}
			autoPageSize
			sx={{
				backgroundColor:
					SoraTheme.palette.gradient.soraBlueToWhiteLinearGradient,
				'& .MuiDataGrid-columnSeparator': {
					display: 'none'
				},
				'& .MuiDataGrid-footerContainer': {
					borderTop: 'none',
					borderBottom: 'none'
				},
				'& .MuiDataGrid-row': {
					cursor: 'pointer'
				},
				'& .MuiDataGrid-row-special': {
					backgroundColor: `${SoraTheme.palette.warning.main}`,
					'&:hover': {
						backgroundColor: `${SoraTheme.palette.warning.mainHover}`
					},
					'&.Mui-selected': {
						backgroundColor: `${SoraTheme.palette.warning.main}`,
						'&:hover': {
							backgroundColor: `${SoraTheme.palette.warning.mainHover}`
						}
					}
				},
				'& .MuiDataGrid-columnHeader:focus': {
					outline: 'none'
				},
				'& .MuiDataGrid-columnHeader:focus-within': {
					outline: 'none'
				},
				'& .MuiDataGrid-cell:focus': {
					outline: 'none'
				},
				border: 'none'
			}}
			components={{
				ColumnSortedAscendingIcon: ArrowDropUpIcon,
				ColumnSortedDescendingIcon: ArrowDropDownIcon,
				Toolbar: enableToolbar ? CustomToolbar : null
			}}
		/>
	);
}

const columnItemShape = {
	field: PropTypes.string.isRequired,
	headerName: PropTypes.string.isRequired,
	flex: PropTypes.number,
	props: PropTypes.object // props specifies mx props check component ColumnItem for links.
};

// Rows shape will be dependant on the columnItemShape.
DataTable.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape(columnItemShape)).isRequired,
	pageSize: PropTypes.number,
	rowsPerPageOptions: PropTypes.number
};

export default DataTable;
