import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

// Utility function
const createTenureRange = () => {
	const numbers = [];
	// eslint-disable-next-line no-plusplus
	for (let num = 1; num <= 40; num++) {
		numbers.push(`${num}`);
	}
	return numbers;
};

// CONSTANTS
const TENURE_RANGE = createTenureRange();

function TenureDropdown({
	dropDownSX,
	dropDownLabelSX,
	sx,
	tenure,
	setTenure
}) {
	return (
		<Grid
			item
			xs={12}
			sm={11}
			md={5}
			lg={4}
			sx={{ marginLeft: 1, marginRight: 1, ...sx }}
		>
			<Dropdown
				helperText="Length of loan in years."
				items={TENURE_RANGE}
				label="Loan Length"
				labelSx={dropDownLabelSX}
				onChange={setTenure}
				selected={tenure}
				sx={dropDownSX}
				variant="outlined"
			/>
		</Grid>
	);
}

TenureDropdown.propTypes = {
	dropDownSX: CommonPropTypes.sx,
	dropDownLabelSX: CommonPropTypes.sx,
	gridColumns: PropTypes.number,
	sx: CommonPropTypes.sx,
	setTenure: PropTypes.func.isRequired,
	tenure: PropTypes.string.isRequired
};

TenureDropdown.defaultProps = {
	dropDownLabelSX: {},
	dropDownSX: {},
	gridColumns: 5,
	sx: {}
};

export default TenureDropdown;
