import PropTypes from 'prop-types';
import { Alert as MuiAlert, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

// Our Query Keys
import {
	ERROR_MESSAGE_DATA,
	INFO_MESSAGE_DATA,
	SUCCESS_MESSAGE_DATA
} from 'shared/query-keys';

// Get Error Message Text
import errorMessageHandler from 'shared/error-messages.json';

// helper
const getErrorMessage = (errorMessage, errorMessageHandler) => {
	const processedErrorMessage = errorMessage.split(' ');
	const isFireBaseError = processedErrorMessage[0] === 'Firebase:';

	// This if statement can be converted to a switch statement once we implement more error handling
	// currently if its a firebase error then we use errorMessageHandler to write a custom error message based on the error provided
	// we can implement the same thing for pinwheel
	// for Sora API errors we simply return the errorMessage and rener our back end teams error
	if (isFireBaseError) {
		const FirebaseErrorType =
			processedErrorMessage[processedErrorMessage.length - 1];
		return errorMessageHandler[processedErrorMessage[0]][FirebaseErrorType];
	}
	return errorMessage;
};

function Alert({ variant }) {
	const queryClient = useQueryClient();
	const getRelevantQueryData = {
		error: ERROR_MESSAGE_DATA,
		success: SUCCESS_MESSAGE_DATA,
		info: INFO_MESSAGE_DATA
	};

	const queryAlertMessage = queryClient.getQueryData(
		getRelevantQueryData[variant]
	);

	const isErrorVariant = variant === 'error';

	// errorMessages are filtered through a prefined list of overwritten errors. Others are simply used.
	const errorMessage =
		isErrorVariant &&
		queryAlertMessage &&
		getErrorMessage(queryAlertMessage, errorMessageHandler);

	const [isOpen, setIsOpen] = useState(true);

	// function called on close.
	const handleClose = () => {
		setIsOpen(false);
	};

	// Cleanup queries when exits
	const handleExited = () => {
		// 100 ms delay to clearing cache BC if immediate it will change SnackBar width BRIEFLY
		setTimeout(() => {
			if (variant === 'error')
				queryClient.removeQueries(ERROR_MESSAGE_DATA);
			if (variant === 'success')
				queryClient.removeQueries(SUCCESS_MESSAGE_DATA);
			if (variant === 'info')
				queryClient.removeQueries(INFO_MESSAGE_DATA);
		}, 100);
	};

	return (
		<Snackbar
			open={isOpen}
			autoHideDuration={5000}
			onClose={handleClose}
			TransitionProps={{ onExited: handleExited }}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<MuiAlert
				severity={variant}
				variant="filled"
				action={
					<IconButton
						aria-label="close"
						color="inherit"
						sx={{ p: 0.5 }}
						onClick={handleClose}
					>
						<CloseIcon color="white" />
					</IconButton>
				}
			>
				{isErrorVariant ? errorMessage : queryAlertMessage}
			</MuiAlert>
		</Snackbar>
	);
}

Alert.propTypes = {
	variant: PropTypes.oneOf(['error', 'success', 'info']).isRequired
};

export default Alert;
