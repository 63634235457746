import { useCallback } from 'react';
import PropTypes from 'prop-types';

// Our Components
import CurrencyTextInput from 'components/Input/CurrencyTextInput';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

// Constants
const MAX_NUMBER_ALLOWED = 10000000; // 100,000,000
const MIN_NUMBER_ALLOW = 0;

// TODO @Daniel create Prop Type support for currency and reuse here.
function OutstandingBalanceInput({
	outstandingBalance,
	setOutstandingBalance,
	sx
}) {
	const handleBalanceOutstandingChange = useCallback((value) => {
		const isValueEmpty = value === '';
		if (isValueEmpty) {
			setOutstandingBalance('');
			return;
		}
		const isAtMaxiumumValueAccepted = value === `${MAX_NUMBER_ALLOWED}.`; // This simply enforces no periods to be allowed when at max value.
		if (isAtMaxiumumValueAccepted) return;
		const startsWithZero = value && value.length > 1 && value[0] === '0';
		if (startsWithZero) {
			const isOnlyZeroes = value.split('').every((char) => char === '0');
			if (isOnlyZeroes) return;
		}

		const number = +value;
		const isWithinRange =
			number >= MIN_NUMBER_ALLOW && number <= MAX_NUMBER_ALLOWED;
		if (isWithinRange) {
			setOutstandingBalance(value);
		}
	}, []);

	return (
		<CurrencyTextInput
			label="Outstanding balance"
			subLabel="Enter the remaining balance on your loan"
			value={outstandingBalance}
			onChange={handleBalanceOutstandingChange}
			sx={sx}
		/>
	);
}

OutstandingBalanceInput.propTypes = {
	outstandingBalance: PropTypes.string.isRequired,
	setOutstandingBalance: PropTypes.func.isRequired,
	sx: CommonPropTypes.sx
};

OutstandingBalanceInput.defaultProps = {
	sx: {}
};

export default OutstandingBalanceInput;
