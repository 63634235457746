import { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Loader as GoogleMapLoader } from '@googlemaps/js-api-loader';

// Our Hooks
import useDebounce from 'hooks/useDebounce';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_CLOUD_API_KEY;

// Dynamically adds the places api
const loader = new GoogleMapLoader({
	apiKey: GOOGLE_API_KEY,
	libraries: ['places']
});

function useGetGooglePlaceAutoComplete(
	addressLine1,
	setSuggestions,
	disableAutoComplete
) {
	const myRef = useRef();
	const [placesService, setPlacesService] = useState(null);
	const [sessionToken, setSessionToken] = useState(null);
	const debouncedAddressRequest = useDebounce(addressLine1, 300);

	const initializeService = () => {
		if (!window.google)
			throw new Error(
				'[react-google-places-autocomplete]: Google script not loaded'
			);
		if (!window.google.maps)
			throw new Error(
				'[react-google-places-autocomplete]: Google maps script not loaded'
			);
		if (!window.google.maps.places)
			throw new Error(
				'[react-google-places-autocomplete]: Google maps places script not loaded'
			);

		setPlacesService(new window.google.maps.places.AutocompleteService());
		setSessionToken(
			new window.google.maps.places.AutocompleteSessionToken()
		);
	};

	// Keeps track of the sessionToken to save on costs
	useImperativeHandle(
		myRef,
		() => ({
			getSessionToken: () => sessionToken,
			refreshSessionToken: () => {
				setSessionToken(
					new window.google.maps.places.AutocompleteSessionToken()
				);
			}
		}),
		[sessionToken]
	);

	// this effect always runs once to init script
	useEffect(() => {
		const init = async () => {
			try {
				if (
					!window.google ||
					!window.google.maps ||
					!window.google.maps.places
				) {
					await loader.load();
				}
				if (placesService === null) initializeService();
			} catch (e) {
				console.error(e);
			}
		};

		if (!disableAutoComplete) init();
	}, [disableAutoComplete]);

	// What makes the api calls.
	useEffect(() => {
		if (
			placesService !== null &&
			debouncedAddressRequest.length > 0 &&
			!disableAutoComplete
		) {
			placesService.getPlacePredictions(
				{
					input: debouncedAddressRequest,
					sessionToken,
					language: 'en',
					componentRestrictions: {
						country: 'us'
					},
					types: ['geocode'],
					fields: ['formatted_address', 'geometry']
				},
				(suggestions) => {
					if (suggestions) {
						try {
							setSuggestions(
								suggestions.map(
									({ description }) => description
								)
							);
						} catch (e) {
							console.error(e);
						}
					}
				}
			);
		}
	}, [debouncedAddressRequest]);
}

export default useGetGooglePlaceAutoComplete;
