import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { getUserLiabilitiesUrl as saveMortgageLiabilityURL } from 'shared/api-urls';

// Our Routes
import { IDENTIFIED_LIABILITIES_ROUTE } from 'routes';

// Our query keys
import { USER_LIABILITIES, WALLET_OVERVIEW_DATA } from 'shared/query-keys';

function useMutateSaveMortgageLiability() {
	const navigate = useNavigate();

	const { tradeLineId } = useParams();

	const queryClient = useQueryClient();

	const urlForRequest = `${saveMortgageLiabilityURL}${tradeLineId}`;

	return useMutation(
		async (mortgageLiabilityData) => {
			const response = await axiosInstance.patch(
				urlForRequest,
				mortgageLiabilityData
			);

			await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
				refetchInactive: true
			});

			await queryClient.invalidateQueries(USER_LIABILITIES, {
				refetchInactive: true
			});

			return response.data;
		},
		{
			onSuccess: () => {
				navigate(IDENTIFIED_LIABILITIES_ROUTE);
			}
		}
	);
}

export default useMutateSaveMortgageLiability;
