import { useMutation, useQueryClient } from 'react-query';

// Our axios instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { userAssetsEndpoint } from 'shared/api-urls';

// Our Query keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Hooks
import useMutateUpdateOnboardingStatus from 'hooks/useMutateUpdateOnboardingStatus';

// Constants
import { ASSETS_UPDATED } from 'shared/constants';

function useMutateSetAssets() {
	const queryClient = useQueryClient();
	const updateOnboardingStatus = useMutateUpdateOnboardingStatus();

	return useMutation(
		async ({ totalAssets, assetIdToUpdate }) => {
			// the api requires the following payload, even though we only have one field on the front end "totalAssets"
			const payload = {
				providerId: 0,
				externalId: null,
				accountId: null,
				assetAmount: totalAssets,
				assetType: null,
				currency: 'USD',
				dataSource: 'Manual'
			};

			const response = await axiosInstance.post(
				userAssetsEndpoint,
				payload
			);

			const makeOnboardingStatusUpdateResponse =
				await updateOnboardingStatus.mutate(ASSETS_UPDATED);

			return response.data.data;
		},
		{
			onError: () => {
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'We had an issue updating your asset information.'
				);
			}
		}
	);
}

export default useMutateSetAssets;
