import { Grid, Box, Paper, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

function LiabilityAttribute({ title, value, gridCol }) {
	const SoraTheme = useTheme();

	const { indigo } = SoraTheme.palette.primary;

	return (
		<Grid item xs={gridCol ?? 6}>
			<Typography variant="h4" sx={{ color: indigo, marginBottom: 1 }}>
				{value}
			</Typography>

			<Typography variant="caption">{title}</Typography>
		</Grid>
	);
}

export default LiabilityAttribute;
