import { Grid } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';

// Our Component
import AutocompleteTextinput from 'components/Input/AutocompleteTextinput';
import Dropdown from 'components/Dropdown/Dropdown';
import TextInput from 'components/Input/TextInput';

// Our input types
import { NUMBER, TEXT } from 'components/Input/Types';

// List of States
import US_STATES from 'components/Dropdown/US_STATES.json';
import useGetGooglePlaceAutoComplete from 'hooks/google/useGetGooglePlaceAutoComplete';

function GetFullAddressPartialForm({
	addressLine1,
	setAddressLine1,
	addressLine2,
	setAddressLine2,
	city,
	children,
	setCity,
	state,
	setState,
	zipCode,
	setZipCode,
	disableAutoComplete,
	setDisableAutoComplete
}) {
	const [suggestions, setSuggestions] = useState([]);

	useGetGooglePlaceAutoComplete(
		addressLine1,
		setSuggestions,
		disableAutoComplete
	);

	const handleSelect = (selectedAddress, updateSelectedAddress) => {
		if (selectedAddress) {
			const selectedSplitAddress = selectedAddress
				.split(',')
				.map((entry) => entry.trim());

			const filteredResults = selectedSplitAddress.filter(
				(entry) => entry !== 'USA'
			);

			const hasAddressData = filteredResults.length > 1;

			if (hasAddressData) {
				const selectedAddressLine1 = filteredResults[0];
				const selectedCity = filteredResults[1];
				const selectedState =
					filteredResults[filteredResults.length - 1];

				setAddressLine1(selectedAddressLine1);
				updateSelectedAddress(selectedAddressLine1);
				setCity(selectedCity);
				setState(selectedState);
			}
		} else {
			setAddressLine1('');
			updateSelectedAddress('');
		}
	};

	const handleOnChange = (newValue) => {
		if (disableAutoComplete) setDisableAutoComplete(!disableAutoComplete);
		setAddressLine1(newValue);
	};

	return (
		<>
			<Grid
				item
				xs={12}
				sm={11}
				md={5}
				lg={4}
				sx={{ marginLeft: 1, marginRight: 1 }}
			>
				<AutocompleteTextinput
					handleChange={handleOnChange}
					handleSelect={handleSelect}
					label="Address"
					value={addressLine1}
					optionList={suggestions}
				/>
			</Grid>

			<Grid
				item
				xs={12}
				sm={11}
				md={5}
				lg={4}
				sx={{ marginLeft: 1, marginRight: 1 }}
			>
				<TextInput
					inputProps={{
						'data-test': 'addressLine2'
					}}
					type={TEXT}
					subLabel="Address line 2"
					value={addressLine2}
					onChange={setAddressLine2}
				/>
			</Grid>

			<Grid
				item
				xs={12}
				sm={11}
				md={5}
				lg={4}
				sx={{ marginLeft: 1, marginRight: 1 }}
			>
				<TextInput
					inputProps={{
						'data-test': 'city'
					}}
					type={TEXT}
					subLabel="City"
					value={city}
					onChange={setCity}
				/>
			</Grid>

			<Grid
				item
				xs={12}
				sm={11}
				md={5}
				lg={4}
				sx={{ marginLeft: 1, marginRight: 1 }}
			>
				<Dropdown
					dataTestTag="state"
					variant="outlined"
					subLabel="State"
					items={US_STATES}
					selected={state}
					onChange={setState}
					placeholder="State"
				/>
			</Grid>

			<Grid
				item
				xs={12}
				sm={11}
				md={5}
				lg={4}
				sx={{ marginLeft: 1, marginRight: 1 }}
			>
				<TextInput
					type={NUMBER}
					subLabel="Zip / Postal Code"
					value={zipCode}
					onChange={setZipCode}
					inputProps={{
						onWheel: (e) => e.target.blur(),
						'data-test': 'zip'
					}}
				/>
			</Grid>

			{children}
		</>
	);
}

GetFullAddressPartialForm.propTypes = {
	addressLine1: PropTypes.string.isRequired,
	setAddressLine1: PropTypes.func.isRequired,
	addressLine2: PropTypes.string.isRequired,
	setAddressLine2: PropTypes.func.isRequired,
	city: PropTypes.string.isRequired,
	setCity: PropTypes.func.isRequired,
	state: PropTypes.string.isRequired,
	setState: PropTypes.func.isRequired,
	zipCode: PropTypes.string.isRequired,
	setZipCode: PropTypes.func.isRequired,
	disableAutoComplete: PropTypes.bool,
	setDisableAutoComplete: PropTypes.func
};

GetFullAddressPartialForm.defaultProps = {
	disableAutoComplete: false,
	setDisableAutoComplete: () => null
};

export default GetFullAddressPartialForm;
