import { useQuery } from 'react-query';

// Api URL
import { getUserLiabilitiesUrl } from 'shared/api-urls';

// Our axios Instance
import axiosInstance from 'services/API/API';

// Our query Keys
import { USER_LIABILITIES } from 'shared/query-keys';

// APP CONSTANTS
import { CREDITCARD } from 'shared/constants';

// staleTime CONST
// 1000 ms -> 1s * 60s -> 60s -> 1m * 5 -> 5m
const FIVE_MINUTES = 1000 * 60 * 5;

// Sorts by TradelineType
const sortedOtherTradeLines = (liabilities) =>
	liabilities.sort(
		(
			{ tradeLineType: tradeLineTypeA },
			{ tradeLineType: tradeLineTypeB }
		) => {
			if (tradeLineTypeA < tradeLineTypeB) return -1;
			if (tradeLineTypeA > tradeLineTypeB) return 1;
			return 0;
		}
	);

function useGetAllUserLiabilities(enabled = true) {
	// if provided a valid tradelineType it will use it otherwise defaults to grabbing all.

	return useQuery(
		USER_LIABILITIES,
		async () => {
			const response = await axiosInstance.get(getUserLiabilitiesUrl);

			const liabilitiesFound = await response.data;
			// Moved Expensive Sorting here.
			// This is tied to the API call
			const sortedLiabilitiesFound = sortedOtherTradeLines(
				liabilitiesFound.data
			);
			return sortedLiabilitiesFound;
		},
		{
			enabled,
			refetchOnWindowFocus: false,
			staleTime: FIVE_MINUTES,
			select: (userLiabilities) => {
				/*
                This select function is to separate the credit card liabilities from the rest of the ones found.
                Why? Well because we have dynamic forms first.
                so it makes sense to provide a list of items that are dynamic first. then credit card since its one page AFTER all the dynamic ones.
                */
				const creditCardItems = userLiabilities.filter(
					({ tradeLineType }) => tradeLineType === CREDITCARD
				);
				const allOtherTradeLines = userLiabilities.filter(
					({ tradeLineType }) => tradeLineType !== CREDITCARD
				);

				const seperatedTradeLinesList =
					allOtherTradeLines.concat(creditCardItems);

				return seperatedTradeLinesList;
			}
		}
	);
}

export default useGetAllUserLiabilities;
