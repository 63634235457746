import { useMutation } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoint urls
import { maskAsAnotherUser } from 'shared/api-urls';

function useMutateUnmask() {
	return useMutation(
		async () => {
			const response = await axiosInstance.post(maskAsAnotherUser, {
				unmasq: true
			});

			const responseData = response.data;

			return responseData;
		},
		{
			onError: (error) => {
				console.error('Error when trying to mask account', error);
			}
		}
	);
}

export default useMutateUnmask;
