// Utils
import { getDefaultApproxPayoffDate } from 'shared/utils';

// APP CONSTANTS
import { STUDENT } from 'shared/constants';

function createDefaultStudentUpdatePayload(studentLiability) {
	const {
		lender,
		monthlyPay,
		outstandingBalance,
		interestRate,
		tenureMonth
	} = studentLiability;

	const approxPayoffDate = getDefaultApproxPayoffDate(STUDENT);

	const studentUpdatePayload = {
		approxPayoffDate,
		degree: 'Other Educational Degree',
		financialGoal: 'LOW_COD',
		fieldOfStudy: '',
		gpa: 0,
		graduationYear: 1900,
		lender,
		lenderType: 'FEDERAL',
		linkedin: '',
		loanType: 'FIXED',
		monthlyPayment: monthlyPay,
		outstandingBalance,
		rate: interestRate,
		school: 'NA',
		tenure: +tenureMonth,
		tradelineType: STUDENT
	};

	return studentUpdatePayload;
}

export default createDefaultStudentUpdatePayload;
