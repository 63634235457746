import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import Footer from 'components/Footer/Footer';

// Our Assets
import SoraLogo from 'assets/icons/SoraLogoBlack.svg';

function Error404Page() {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	return (
		<Grid
			container
			sx={{
				height: '99vh',
				width: '100vw',
				alignItems: 'center'
			}}
		>
			<Grid
				container
				item
				xs={12}
				sx={{
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<Box
					component="img"
					src={SoraLogo}
					alt="Sora logo"
					sx={{
						maxWidth: 300,
						maxHeight: 300
					}}
				/>

				<Typography
					variant="h1"
					gutterBottom
					component="div"
					sx={{
						marginTop: 10,
						color: primary.main
					}}
				>
					404
				</Typography>
				<Typography
					variant="h3"
					gutterBottom
					component="div"
					sx={{
						marginTop: 6,
						color: primary.main
					}}
				>
					Page Not Found
				</Typography>
			</Grid>

			<Grid
				container
				item
				xs={12}
				sm={12}
				sx={{
					justifyContent: { xs: 'space-evenly', sm: 'center' },
					gap: { xs: 0, sm: 12 },
					alignSelf: 'flex-end'
				}}
			>
				<Footer />
			</Grid>
		</Grid>
	);
}

export default Error404Page;
