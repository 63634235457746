import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import englishLocale from 'date-fns/locale/en-US';
import { useState } from 'react';

import CommonPropTypes from 'shared/prop-types';

function StandardDatePicker({
	disabled,
	error,
	errorText,
	helperText,
	inputFormat,
	inputProps,
	label,
	onChange,
	sx,
	labelSx,
	value,
	views
}) {
	const [wasChecked, setWasChecked] = useState(false);

	return (
		<>
			{label && (
				<Typography
					variant="subtitle1"
					sx={{ marginBottom: 1, ...labelSx }}
				>
					{label}
				</Typography>
			)}
			<LocalizationProvider
				dateAdapter={AdapterDateFns}
				adapterLocale={englishLocale}
			>
				<DatePicker
					openTo="year"
					disabled={disabled}
					inputFormat={inputFormat}
					value={value}
					views={views}
					onChange={onChange}
					sx={{
						marginTop: 4,
						width: '100%',
						...sx
					}}
					inputProps={inputProps}
					renderInput={(params) => (
						<TextField
							{...params}
							sx={{ width: '100%' }}
							onBlur={() => {
								if (wasChecked === false) setWasChecked(true);
							}}
							error={wasChecked && error}
							helperText={
								wasChecked && error ? errorText : helperText
							}
						/>
					)}
				/>
			</LocalizationProvider>
		</>
	);
}

StandardDatePicker.propTypes = {
	disabled: PropTypes.bool,
	error: PropTypes.bool,
	errorText: PropTypes.string,
	helperText: PropTypes.string,
	inputFormat: PropTypes.string,
	inputProps: CommonPropTypes.sx,
	label: PropTypes.string,
	labelSx: CommonPropTypes.sx,
	onChange: PropTypes.func,
	sx: CommonPropTypes.sx,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
		PropTypes.number
	]),
	views: PropTypes.arrayOf(PropTypes.oneOf(['day', 'month', 'year']))
};

StandardDatePicker.defaultProps = {
	disabled: false,
	error: false,
	errorText: 'something went wrong',
	helperText: '',
	inputFormat: 'MM/dd/yyyy',
	inputProps: {},
	label: 'This is a label',
	labelSx: {},
	onChange: () => {},
	sx: {},
	value: '1985-02-15T07:00:00.000Z',
	views: ['year', 'month', 'day'] // byDefault will show Year Month and Day
};

export default StandardDatePicker;
