import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
// Our axios Instance
import axiosInstance from 'services/API/API';

// Sora API endpoint
import { userProfile } from 'shared/api-urls';

// React Query Keys
import { ADVISOR_DATA } from 'shared/query-keys';

// Routes
import { VERIFY_ADVISOR_ROUTE } from 'routes';

const TWENTY_MINUTE_STALE_TIME = 1000 * 60 * 20;

const TWENTY_MINUTE_CACHE_TIME = TWENTY_MINUTE_STALE_TIME;

const compareAdvisorHashCodes = (cachedAdvisorData, advisorDataFromAPI) =>
	cachedAdvisorData?.hash === advisorDataFromAPI?.hash;

function useGetAdvisorProfile(enable) {
	const { pathname } = useLocation();

	return useQuery(
		ADVISOR_DATA,
		async () => {
			// Let's only make the api call when we're on the verify advisor path.
			// This means paths past this point should have cached data and we should simply retrieve the data
			const isOnVerifyAdvisorPath =
				pathname.includes(VERIFY_ADVISOR_ROUTE);

			if (isOnVerifyAdvisorPath) {
				const res = await axiosInstance.get(userProfile);

				const advisorProfileData = res.data.data;

				const cachedAdvisorData = localStorage.getItem(ADVISOR_DATA);

				const hasNoCachedData = cachedAdvisorData === null;

				if (hasNoCachedData) {
					// we have not cached any data, lets just simply cache it and return the advisorData from the profile.

					localStorage.setItem(
						ADVISOR_DATA,
						JSON.stringify(advisorProfileData)
					);

					return advisorProfileData;
				}

				const isSameAdvisor = compareAdvisorHashCodes(
					cachedAdvisorData,
					advisorProfileData
				);

				// If the advisor is the same return the cachedAdvisorData
				return isSameAdvisor ? cachedAdvisorData : advisorProfileData;
			}

			// if we are past the verify page just retrieve the data and return it.
			const cachedAdvisorData = localStorage.getItem(ADVISOR_DATA);

			const parsedCachedAdvisorData = JSON.parse(cachedAdvisorData);
			// we may want to return the parsed data instead
			return parsedCachedAdvisorData;
		},
		{
			// this staleTime is equivalent to 1 minute
			// this stalTime is added to resolve the navbar refreshing every time we click on a button in the nav bar
			// this will be tweaked post mvp
			cacheTime: TWENTY_MINUTE_CACHE_TIME,
			enabled: enable,
			staleTime: TWENTY_MINUTE_STALE_TIME
		}
	);
}

export default useGetAdvisorProfile;
