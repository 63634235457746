import { useMutation, useQueryClient } from 'react-query';
import emailjs from '@emailjs/browser';

// Our Query Keys
import { SUCCESS_MESSAGE_DATA } from 'shared/query-keys';

// EmailJs variables
const EMAIL_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const EMAIL_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_NEW_LOAN_TEMPLATE_ID;
const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

function useMutateSendStandardEmail() {
	const queryClient = useQueryClient();

	return useMutation(
		async ({ emailSubject, emailBody, prospectiveClientInfo }) => {
			const { firstName, lastName, email } = prospectiveClientInfo;

			const emailJSParams = {
				name: `${firstName} ${lastName}`,
				emailAddress: email,
				subject: emailSubject,
				message: emailBody
			};

			await emailjs.send(
				EMAIL_SERVICE_ID,
				EMAIL_TEMPLATE_ID,
				emailJSParams,
				EMAIL_PUBLIC_KEY
			);
		},
		{
			onSuccess: () => {
				queryClient.setQueryData(
					SUCCESS_MESSAGE_DATA,
					'Your advisor will reach out!'
				);
			}
		}
	);
}

export default useMutateSendStandardEmail;
