import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';

function SoraSkeleton({ sx, variant }) {
	return (
		<Skeleton variant={variant} sx={{ width: 300, height: 300, ...sx }} />
	);
}

SoraSkeleton.propTypes = {
	variant: PropTypes.oneOf(['circular', 'rectangular', 'rounded', 'text'])
};

SoraSkeleton.defaultProps = {
	sx: {},
	variant: 'rectangular'
};

export default SoraSkeleton;
