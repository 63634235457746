import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// Firebase and google analytics setup
import { initializeApp } from 'firebase/app';

// MUI
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

// Our Components
import App from './App';

// Our Theme
import SoraTheme from './theme';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
initializeApp(firebaseConfig);

ReactDOM.render(
	<HelmetProvider>
		<React.StrictMode>
			<ThemeProvider theme={SoraTheme}>
				<CssBaseline />
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</ThemeProvider>
		</React.StrictMode>
	</HelmetProvider>,
	document.getElementById('root')
);
