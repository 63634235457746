import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Our Components
import SimpleGradientLoading from 'components/Loader/SimpleGradientLoading';

// Our Hooks
import useGetMethodfiLiabilities from 'hooks/methodfi/useGetMethodfiLiabilities';
import useGetAllUserLiabilities from 'hooks/client/useGetAllUserLiabilities';
import useMutateAsyncUpdateAllLiabilities from 'hooks/client/liabilities/mutations/useMutateAsyncUpdateAllLiabilities';

// Our Routes
import { IDENTIFIED_LIABILITIES_ROUTE } from 'routes';

function ProcessingMethodfiLiabilitiesPage() {
	const navigate = useNavigate();

	const getMethodfiLiabilities = useGetMethodfiLiabilities();
	const updateAllLiabilities = useMutateAsyncUpdateAllLiabilities();

	const { isSuccess } = getMethodfiLiabilities;

	const { data, isSuccess: isGetAllUserLiabilitiesSucess } =
		useGetAllUserLiabilities(isSuccess);

	useEffect(() => {
		getMethodfiLiabilities.mutate({});
	}, []);

	useEffect(() => {
		if (isGetAllUserLiabilitiesSucess) {
			updateAllLiabilities.mutate(data, {
				onSuccess: () => {
					navigate(IDENTIFIED_LIABILITIES_ROUTE);
				}
			});
		}
	}, [isGetAllUserLiabilitiesSucess, navigate]);

	return <SimpleGradientLoading />;
}

export default ProcessingMethodfiLiabilitiesPage;
