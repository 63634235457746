import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Api Endpoints
import { getMethodfiLiabilities } from 'shared/api-urls';

// Additional mutations
import useMutateUpdateOnboardingStatus from 'hooks/useMutateUpdateOnboardingStatus';

// Our Constants
import { LIABILITY_ACCOUNT_CONNECTED } from 'shared/constants';

function useGetMethodfiLiabilities() {
	const navigate = useNavigate();
	const updateOnboardingStatus = useMutateUpdateOnboardingStatus();

	const { accountHolderId } = useParams();

	return useMutation(
		async () => {
			const response = await axiosInstance.get(
				`${getMethodfiLiabilities}${accountHolderId}`
			);
			const methodfiData = response.data.data;

			// variable here is not needed but the AWAIT is.
			// stored in a variable for contextual purposes.
			const makeOnboardingStatusUpdateResponse =
				await updateOnboardingStatus.mutate(
					LIABILITY_ACCOUNT_CONNECTED
				);

			return methodfiData;
		},
		{
			onError: () => {
				navigate('/client/onboarding/unable-to-verify');
			}
		}
	);
}

export default useGetMethodfiLiabilities;
