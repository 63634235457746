import { Box, Grid } from '@mui/material';

// Our Components
import Footer from 'components/Footer/Footer';
import MenuAppBar from 'components/MenuAppBar';

// Our Common Props
import CommonPropTypes from 'shared/prop-types';

// Our Assets
import IntroPageArt from 'assets/icons/Art/IntroPageArt.svg';

function IntroPageFrame({ children }) {
	// A container for a basic 12 column grid
	return (
		<Grid
			container
			sx={{
				height: '99vh',
				width: '100%'
			}}
		>
			<Grid
				item
				sx={{
					display: { xs: 'block', md: 'none' },
					maxHeight: 60,
					marginBottom: 2
				}}
			>
				<MenuAppBar />
			</Grid>

			<Grid
				item
				xs={12}
				md={6}
				sx={{
					display: {
						md: 'block',
						height: { xs: 'auto', md: '100vh' }
					}
				}}
			>
				<Box
					component="img"
					src={IntroPageArt}
					sx={{ width: '100%', height: '100%' }}
				/>
			</Grid>

			<Grid
				container
				item
				xs={12}
				md={6}
				sx={{
					alignItems: 'flex-end',
					background: 'linear-gradient(180deg, #EBF3F9 0%, #FFF 100%)'
				}}
			>
				{children}
				<Grid
					container
					item
					xs={12}
					sx={{
						borderTop: '0.25px solid #000',
						justifyContent: 'center',
						alignItems: 'center',
						gap: 5,
						paddingTop: 2,
						paddingBottom: 2
					}}
				>
					<Footer />
				</Grid>
			</Grid>
		</Grid>
	);
}

IntroPageFrame.propTypes = {
	sx: CommonPropTypes.sx
};

IntroPageFrame.defaultProps = {};

export default IntroPageFrame;
