import { Box, SwipeableDrawer, Typography } from '@mui/material';

// Our Components
import NavOptions from 'components/NavMenu/NavOptions';
import SimpleMobileAppBar from 'components/AppBar/SimpleMobileAppBar';
import SoraSkeleton from 'components/Loader/Skeleton';

// Hooks
import useGetAdvisorProfile from 'hooks/advisor/useGetAdvisorProfile';

const Nav = ({ isMenuOpen, toggleMenuOpen }) => {
	const { isLoading, isSuccess, data } = useGetAdvisorProfile(true);

	if (isLoading) {
		return <SoraSkeleton />;
	}

	if (isSuccess) {
		const {
			profilePicUrl: advisorProfilePic,
			companyName,
			firstName,
			lastName
		} = data;

		return (
			<SwipeableDrawer
				open={isMenuOpen}
				onOpen={toggleMenuOpen}
				transitionDuration={350}
				onClose={toggleMenuOpen}
				PaperProps={{
					sx: {
						background:
							'linear-gradient(180deg, #B0CDE4 0%, #FFF 100%)',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						width: '100%'
					}
				}}
			>
				<Box>
					<SimpleMobileAppBar toggleMenuOpen={toggleMenuOpen} />
					<NavOptions isMenuOpen={isMenuOpen} />
				</Box>

				<Box sx={{ paddingLeft: 2 }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'flex-start',
							marginTop: 4,
							marginBottom: 4
						}}
					>
						<Box
							component="img"
							src={advisorProfilePic}
							sx={{
								maxHeight: 80,
								maxWidth: '50%',
								borderRadius: 3.14 * Math.pow(2, 4),
								marginBottom: 2
							}}
						/>

						<Typography
							variant="subtitle1"
							sx={{ marginBottom: 1 }}
						>
							{firstName} {lastName},
						</Typography>

						<Typography variant="body2">{companyName}</Typography>
					</Box>
				</Box>
			</SwipeableDrawer>
		);
	}
};

export default Nav;
