import { Grid, Typography } from '@mui/material';

import CommonPropTypes from 'shared/prop-types';

function LiabilityFormHeading({ headingText, variant, sx }) {
	return (
		<Grid
			item
			container
			sx={{
				marginLeft: { sm: 10, xs: 0 }
			}}
			xs={12}
			sm={12}
		>
			<Typography
				data-test="heading"
				variant={variant}
				sx={{
					marginLeft: 3,
					marginTop: 4,
					marginBottom: 2,
					align: 'center',
					...sx
				}}
			>
				{headingText}
			</Typography>
		</Grid>
	);
}

LiabilityFormHeading.propTypes = {
	sx: CommonPropTypes.sx,
	variant: CommonPropTypes.typography
};

LiabilityFormHeading.defaultProps = {
	sx: {},
	variant: 'h1Gl'
};

export default LiabilityFormHeading;
