import { Autocomplete, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';

// Our Prop types
import CommonPropTypes from 'shared/prop-types';

// for prop defintions such as freeSolo please refer to this doc
// https://mui.com/material-ui/react-autocomplete/#free-solo

function AutocompleteTextinput({
	autoComplete,
	autoSelect,
	autoCompleteSX,
	disableCloseOnSelect,
	dataTestTag,
	freeSolo,
	forcePopupIcon,
	getOptionLabel,
	handleChange,
	handleSelect,
	helperText,
	label,
	multiple,
	optionList,
	renderOption,
	sx,
	value,
	withoutFilter
}) {
	const [autoCompleteValue, setAutoCompleteValue] = useState(
		multiple ? [] : null
	);

	const determineFilter = withoutFilter ? { filterOptions: (x) => x } : {};

	return (
		<Autocomplete
			{...determineFilter}
			sx={autoCompleteSX}
			autoSelect={autoSelect}
			autoHighlight
			autoComplete={autoComplete}
			disableCloseOnSelect={disableCloseOnSelect}
			getOptionLabel={getOptionLabel}
			freeSolo={freeSolo}
			inputValue={value}
			multiple={multiple}
			onInputChange={(e, newInputValue) => handleChange(newInputValue)}
			options={optionList}
			onChange={(e, newValue) => {
				if (handleSelect) {
					handleSelect(newValue, setAutoCompleteValue);
				} else {
					setAutoCompleteValue(newValue);
				}
			}}
			renderInput={(params) => (
				<>
					{label && (
						<Typography
							variant="subtitle1"
							sx={{ marginBottom: 1 }}
						>
							{label}
						</Typography>
					)}
					<TextField
						{...params}
						data-test={dataTestTag}
						type="text"
						helperText={helperText}
						variant="outlined"
						sx={{ ...sx }}
					/>
				</>
			)}
			forcePopupIcon={forcePopupIcon}
			renderOption={renderOption}
			value={autoCompleteValue}
		/>
	);
}

AutocompleteTextinput.propTypes = {
	autoComplete: PropTypes.bool,
	autoSelect: PropTypes.bool,
	autoCompleteSX: CommonPropTypes.sx,
	disableCloseOnSelect: PropTypes.bool,
	dataTestTag: PropTypes.string,
	freeSolo: PropTypes.bool,
	forcePopupIcon: PropTypes.bool,
	helperText: PropTypes.string,
	multiple: PropTypes.bool,
	withoutFilter: PropTypes.bool
};

AutocompleteTextinput.defaultProps = {
	autoComplete: false,
	autoSelect: false,
	autoCompleteSX: {},
	disableCloseOnSelect: false,
	dataTestTag: 'addressLine1',
	freeSolo: true,
	forcePopupIcon: false,
	helperText: 'Address line 1',
	multiple: false,
	withoutFilter: true
};

export default AutocompleteTextinput;
