import { Box, Grid, Typography } from '@mui/material/index';
import { useTheme } from '@emotion/react';

// Our Components
import SimpleMobileAppBar from 'components/AppBar/SimpleMobileAppBar';

// Our assets
import PigIcon from 'assets/icons/pig.svg';

function SimpleGradientLoading({ loadingMessage, loadingSubMessage }) {
	const SoraTheme = useTheme();

	const { soraSimpleGradient } = SoraTheme.palette.gradient;

	return (
		<>
			<Grid
				item
				xs={12}
				sm={0}
				sx={{ display: { xs: 'block', sm: 'none' } }}
			>
				<SimpleMobileAppBar />
			</Grid>
			<Grid
				container
				sx={{
					height: '100vh',
					width: '100vw',
					background: soraSimpleGradient,
					border: '1px solid #000',
					boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
				}}
			>
				<Grid
					container
					item
					xs={12}
					sx={{
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<Box
						component="img"
						src={PigIcon}
						sx={{ marginBottom: 4 }}
					/>
					{/* minimum width is 92 to contain the please do not refresh text */}
					<Box
						sx={{
							width: '80%',
							overflowWrap: 'break-word',
							textAlign: 'center'
						}}
					>
						<Typography
							variant="h2"
							sx={{
								fontFamily: 'Gascogne-light',
								fontWeight: 100,
								fontSize: 32,
								lineHeight: '1.25em',
								marginBottom: 2
							}}
						>
							{loadingMessage ?? 'Please do not refresh the page'}
						</Typography>
						<Typography variant="body1">
							{loadingSubMessage ??
								'Sora is working to display and analyze your existing debt.'}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</>
	);
}

export default SimpleGradientLoading;
