import { useState, useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { isMobilePhone, isDate } from 'validator';
import { useQueryClient } from 'react-query';

// Our components
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Input from 'components/Input/TextInput';
import LiablitiesProcessing from 'components/Methodfi/LiabilitiesProcessing';
import Loader from 'components/Loader';
import PhoneNumberInput from 'components/Input/PhoneNumberInput';
import { PrimaryButton } from 'components/Button/Button';
import SSNTextInput from 'components/Input/SSNTextInput';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';

// Our hooks
import useMutateAddProfileData from 'hooks/useMutateAddProfileData';
import useMutateCreateClient from 'hooks/advisor/useMutateCreateClient';
import useMutateProgramaticAuth from 'hooks/client/useMutationProgramaticAuth';
import useGetAdvisorProfile from 'hooks/advisor/useGetAdvisorProfile';
import useMethodfiConnect from 'components/Methodfi/useMethodfiConnect';
import useMutateUnmask from 'hooks/admin/useMutateUnmask';
import useMutateSetIncome from 'hooks/client/updatePersonalFinancialData/useMutateSetIncome';
import useMutateSetAssets from 'hooks/client/updatePersonalFinancialData/useMutateSetAssets';

// Our Query Keys
import { USER_DATA } from 'shared/query-keys';

// Our utils
import {
	isValidEmail,
	isSubmissionReady,
	generateRandomEmail
} from 'shared/utils';
import convertDateToEndpointFormat from 'shared/formatting/convertDateToEndpointFormat';
import isValidSSN from 'shared/validation/isValidSSN';

// Constants
import { TEXT } from 'components/Input/Types';
import PhoneNumberWhitelist from 'shared/phone-number-whitelist.json';

function AddClientDetails() {
	const queryClient = useQueryClient();

	// Important flag that gets set on successful authentication.
	const hasUserData = queryClient.getQueryData(USER_DATA);

	// API Calls
	const updateProfileData = useMutateAddProfileData();
	const createClientAccount = useMutateCreateClient();
	const unMaskAccount = useMutateUnmask();

	const updateIncome = useMutateSetIncome();
	const updateAssets = useMutateSetAssets();

	// State related to form data.
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [dob, setDob] = useState(null);
	const [ssn, setSSN] = useState('');
	const [email, setEmail] = useState('');

	// Methodfi Related State
	const [beginMethodTokenRequest, setBeginMethodTokenRequest] =
		useState(false);
	const [successfulMethodfiConnection, setSuccessfulMethodfiConnection] =
		useState(false);

	// Client Financial State
	const [income, setIncome] = useState('');
	const [assets, setAssets] = useState('');

	const programaticAuth = useMutateProgramaticAuth(
		setBeginMethodTokenRequest
	);

	const isLoading = [
		createClientAccount,
		programaticAuth,
		updateAssets,
		updateIncome,
		updateProfileData,
		unMaskAccount
	].some(({ isLoading }) => isLoading === true);

	const userDetails = { dob, firstName, lastName, phoneNumber, ssn };

	const { isSuccess, data: advisorProfileData } = useGetAdvisorProfile(true);

	useMethodfiConnect(
		userDetails,
		beginMethodTokenRequest,
		setBeginMethodTokenRequest,
		setSuccessfulMethodfiConnection
	);

	const requiredFormData = [email, firstName, lastName, ssn];

	const isValidDate = useMemo(() => {
		const isStringDate = typeof dob === 'string';
		const isDateType = dob instanceof Date;
		if (isStringDate && dob !== '') {
			const isDateCheckWithConversion = isDate(new Date(dob));
			return isDateCheckWithConversion;
		}

		if (isDateType) {
			return isDate(dob);
		}
		// if dob is null this returns false;
		return false;
	}, [dob]);

	const isValidPhoneNumber = useMemo(() => {
		if (PhoneNumberWhitelist.includes(phoneNumber)) {
			return true;
		}
		return isMobilePhone(phoneNumber, 'en-US');
	}, [phoneNumber]);

	const isEmailReady = isValidEmail(email);

	const isSSNValid = useMemo(() => isValidSSN(ssn), [ssn]);

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(requiredFormData) &&
			isSSNValid &&
			phoneNumber !== '' &&
			isValidPhoneNumber &&
			isValidDate,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[...requiredFormData, phoneNumber, dob] // phone number and dob have other criteria other than not just being empty.
	);

	const handleDobChange = (value) => {
		const isDateInvalid = value === null;
		if (isDateInvalid) {
			// ensures date is a string.
			setDob('');
			return;
		}
		setDob(value);
	};

	// Handle Additional Details form submit,
	const submitUserInformation = () => {
		// Gather and store the data entered into form and associated companyId given from backend if found
		const cleanedPhoneNumber =
			phoneNumber && phoneNumber.replaceAll('-', '');

		const dobYearMonthDayFormat = convertDateToEndpointFormat(dob);

		if (!hasUserData) {
			const randomClientEmail = generateRandomEmail(firstName, lastName);
			const clientPassword = process.env.REACT_APP_CLIENT_PASSWORD

			// Advisor Data
			const {
				id: advisorId,
				firstName: advisorFirstName,
				lastName: advisorLastName,
				companyName: advisorCompanyName,
				email: advisorEmail
			} = advisorProfileData;

			const createClientPayload = {
				accountCreationPayload: {
					advisorEmail,
					clientPassword: clientPassword,
					clientFirstName: firstName,
					clientLastName: lastName,
					clientPhone: phoneNumber,
					clientDob: dobYearMonthDayFormat,
					clientEmail: randomClientEmail,
					isProspectiveClient: true
				},
				logPayload: {
					advisorId,
					advisorFirstName,
					advisorLastName,
					advisorCompanyName,
					firstName,
					lastName,
					phoneNumber,
					dob,
					clientEmail: randomClientEmail,
					piiNumber: ssn,
					timestamp: new Date().toISOString(),
					income,
					assets
				}
			};

			createClientAccount.mutate(createClientPayload, {
				onSuccess: () => {
					unMaskAccount.mutate(
						{},
						{
							onSuccess: () =>
								programaticAuth.mutate(
									{
										clientEmail: randomClientEmail,
										clientPassword
									},
									{
										onSuccess: () => {
											updateIncome.mutate({
												totalOtherAnnualIncome: income
											});

											updateAssets.mutate({
												totalAssets: assets
											});
										}
									}
								)
						}
					);
				}
			});
		} else {
			const userData = {
				firstName,
				lastName,
				phoneNumber: cleanedPhoneNumber,
				dob: dobYearMonthDayFormat
			};

			updateProfileData.mutate(userData, {
				onSuccess: () => setBeginMethodTokenRequest(true)
			});
		}
	};

	if (successfulMethodfiConnection) return <LiablitiesProcessing />;

	if (isSuccess)
		return (
			<>
				<Grid
					item
					xs={12}
					sx={{
						marginLeft: { sm: 12, xs: 2 },
						marginBottom: 4
					}}
				>
					<Box>
						<Typography data-test="heading" variant="h1Gl">
							Debt Analyzer
						</Typography>
						<Typography variant="body1" sx={{ marginTop: 4 }}>
							Securely onboard and see your personalized debt
							analysis.
						</Typography>
					</Box>
				</Grid>

				<Grid
					component="form"
					noValidate
					container
					item
					sx={{
						marginLeft: { sm: 10, xs: 0 },
						marginRight: 2
					}}
					spacing={2}
				>
					<Grid item xs={12} md={6} lg={5}>
						{/* First Name */}
						<Input
							id="firstName"
							label="First Name"
							type={TEXT}
							onChange={setFirstName}
							value={firstName}
							subLabel="Enter your legal name"
							sx={{
								width: '100%'
							}}
							inputProps={{
								'data-test': 'firstName'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6} lg={5}>
						{/* Last Name */}
						<Input
							id="lastName"
							label="Last Name"
							type={TEXT}
							onChange={setLastName}
							value={lastName}
							subLabel="Enter your legal name"
							sx={{
								width: '100%'
							}}
							inputProps={{
								'data-test': 'lastName'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6} lg={5}>
						{/* Phone Number */}
						<PhoneNumberInput
							phoneNumber={phoneNumber}
							onChange={setPhoneNumber}
							isValidPhoneNumber={isValidPhoneNumber}
							inputProps={{
								'data-test': 'phoneNumber'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6} lg={5}>
						<StandardDatePicker
							id="dob"
							label="Date of Birth"
							helperText="Enter your birth date"
							onChange={handleDobChange}
							value={dob}
							error={dob === null || !isValidDate}
							errorText="Date of birth needs to be a valid date"
							inputProps={{
								'data-test': 'dob'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6} lg={5}>
						<Input
							id="email"
							label="Email"
							type="email"
							onChange={setEmail}
							inputProps={{
								'data-test': 'email-input'
							}}
							value={email}
							error={isEmailReady}
							helperText={
								isEmailReady
									? 'Please enter a valid email address'
									: ''
							}
						/>
					</Grid>

					<Grid item xs={12} md={6} lg={5}>
						{/* Optional SSN */}
						<SSNTextInput
							ssnValue={ssn}
							onChange={setSSN}
							tabIndex=""
						/>
					</Grid>

					<Grid item xs={12} md={6} lg={5}>
						<CurrencyTextInput
							id="income"
							label="Income"
							onChange={setIncome}
							value={income}
							subLabel="Optional"
							sx={{
								width: '100%'
							}}
							inputProps={{
								'data-test': 'income'
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={5}>
						<CurrencyTextInput
							id="assets"
							label="Assets"
							onChange={setAssets}
							value={assets}
							subLabel="Optional"
							sx={{
								width: '100%'
							}}
							inputProps={{
								'data-test': 'assets'
							}}
						/>
					</Grid>

					<Grid
						item
						lg={12}
						sx={{ display: { xs: 'none', lg: 'block' } }}
					/>

					<Grid item xs={12} md={2} xl={1}>
						{!isLoading && (
							<>
								<PrimaryButton
									data-test="submit"
									onClick={submitUserInformation}
									sx={{
										marginBottom: 2,
										width: '100%',
										padding: {
											xs: 2,
											md: 2
										}
									}}
									disabled={!isFormReady}
								>
									Next
								</PrimaryButton>
							</>
						)}

						{isLoading && (
							<Loader
								boxSX={{
									justifyContent: 'center',
									alignItems: 'center',
									minHeight: 80
								}}
							/>
						)}
					</Grid>
				</Grid>
			</>
		);

	/* Do not remove below */

	return <div />;
}

export default AddClientDetails;
