const isValidSSN = (ssn) => {
	// return early for most common case
	if (ssn === '000-00-000' || ssn === '00000000') return false;
	// Remove any non-digit characters
	const cleanedSSN = ssn.replace(/\D/g, '');

	// Check for the correct length
	if (cleanedSSN.length !== 9) {
		return false; // Invalid length
	}

	// Check if parts are valid numbers
	if (
		!/^\d{3}$/.test(cleanedSSN.substring(0, 3)) ||
		!/^\d{2}$/.test(cleanedSSN.substring(3, 5)) ||
		!/^\d{4}$/.test(cleanedSSN.substring(5, 9))
	) {
		return false; // Parts are not valid numbers
	}

	return true; // Valid SSN
};

export default isValidSSN;
